import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProductService } from 'src/app/shared/services/product.service';

@Component({
  selector: 'app-wishlist-single-item',
  templateUrl: './wishlist-single-item.component.html',
  styleUrls: ['./wishlist-single-item.component.scss']
})
export class WishlistSingleItemComponent implements OnInit {
  @Input() product: any;
  @Input() imageUrl = '';

  constructor(
    public productService: ProductService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    console.log('product in wishlist ', this.product); 
  }

  removeItem() {
    this.productService.removeWishlistItem(this.product);
  }

  async addToCart() {
    const status = await this.productService.addToCart(this.product);
    if(status) {
      this.router.navigate(['/shop/cart']);
      // this.removeItem();
    }
  }

}
