import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ViewportScroller } from "@angular/common";
import { ProductService } from "../../../shared/services/product.service";
import { Product } from "../../../shared/classes/product";
import { ParamMap, NavigationEnd } from "@angular/router";
import * as _ from "lodash";
import { CommonService } from "src/app/shared/services/common.service";
import { CategoriesService } from "src/app/shared/services/categories";
import { MatBottomSheet } from "@angular/material/bottom-sheet";

// declare let fbq: Function;

@Component({
  selector: "app-collection-infinitescroll",
  templateUrl: "./collection-infinitescroll.component.html",
  styleUrls: ["./collection-infinitescroll.component.scss"],
})
export class CollectionInfinitescrollComponent implements OnInit {
  public grid: string = "product-col-container1";
  public layoutView: string = "grid-view";
  public all_products: any[] = [];
  public products: any[] = [];
  public allItems: Product[] = [];
  public brands: any[] = [];
  public colors: any[] = [];
  public size: any[] = [];
  public minPrice: number = 0;
  public maxPrice: number = 5000;
  public tags: any[] = [];
  public category: string;
  public pageNo: number = 1;
  public paginate: any = {}; // Pagination use only
  public sortBy: string; // Sorting Order
  public mobileSidebar: boolean = false;
  public loader: boolean = true;
  public finished: boolean = false; // boolean when end of data is reached
  public addItemCount = 8;
  public start = 0;
  public limit = 25;
  public allShownProducts: any[] = [];

  public filterDataShapes: any[] = [];
  public filterDataPrice: any[] = [];
  public filterDataBrand: any[] = [];
  public filterDataType: any[] = [];
  public categoryRouterName: any;
  breadCrumbList: any[] = [];

  formsIdParams: any = {};
  formsShapes: any[] = [];
  formShapes4: any = {};
  filterBody: any;

  filterBrandsNamesButtons: any[] = [];
  filterFormsNamesButtons: any[] = [];
  filterPricesNamesButtons: any[] = [];

  // CUSTOM PRICES MULTI SELECT
  searchMultiPrices: any[] = [];
  prices: any;
  filterTypes: any;
  pricesBody: any[] = [];

  newOrdNum = { minPrice: 100, maxPrice: 200 };
  // public filterDataOptions: any[] = [];

  loading: boolean = true;
  loadingImgUrl: string = "";
  category_name: string = "";
  filterLoading: boolean = false;
  public formShapeCollapse: boolean = false;
  public getNoData: boolean = false;
  sub_categories: any[];
  fetchedIds: Set<any> = new Set();
  // declare gtag: Function;
  category_id: any;
  parent_id: string;
  categoriesIds: any;
  test: any = [];
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private viewScroller: ViewportScroller,
    public productService: ProductService,
    public commonService: CommonService,
    public categoriesService: CategoriesService,
    private bottomSheet: MatBottomSheet
  ) {
    /* -------------------------------------------------------------------------- */
    /*                             DETECT URL CHANGES                             */
    /* -------------------------------------------------------------------------- */
    this.router.events.subscribe((val) => {
      this.allShownProducts = [];
      this.start = 0;

      // console.log(val instanceof NavigationEnd);
    });

    /* ------------------------ END OF DETECT URL CHANGES ----------------------- */

    // Get Query params..
    this.route.queryParams.subscribe((params: any) => {
      this.products = [];
      this.finished = false;
      let clientType = JSON.parse(localStorage.getItem('currentUserFront'))?.user_type
      console.log(this.route.snapshot.url[1]?.path == 'promo' && clientType !== 'contributor');
      console.log(this.route.snapshot.url , clientType !== 'contributor');
      
      if (this.route.snapshot.url[1]?.path == 'promo' && clientType !== 'contributor') {
        this.router.navigate(['/'])
      }
      /* -------------------------------------------------------------------------- */
      /*                START GET PARAMETERS OF FORM SHAPES FROM URL                */
      /* -------------------------------------------------------------------------- */
      const queryString = window.location.search;
      const subQueryString = queryString.substring(1);

      let formShapes1 = [];
      let formShapes2 = [];
      let formShapes3 = [];
      let formShapes4 = {};
      formShapes1 = subQueryString ? subQueryString.split("&") : [];
      for (let index = 0; index < formShapes1.length; index++) {
        if (formShapes1[index].slice(0, 5) == "form_") {
          formShapes2.push(formShapes1[index]);
        }
      }
      for (let index = 0; index < formShapes2.length; index++) {
        formShapes3.push(
          formShapes2[index].split(/, (?=[^,]+:)/).map((s) => s.split("="))
        );
      }

      for (let index = 0; index < formShapes3.length; index++) {
        formShapes4[formShapes3[index][0][0]] = formShapes3[index][0][1]
          .split(/, (?=[^,]+:)/)
          .map((s) => s.split(","))[0];
      }

      this.formShapes4 = formShapes4;

      /* ---------------- MAKING THE BODY OF API FOR FILTERED DATA ---------------- */

      this.filterBody = {};
      let arr = [];

      for (let index = 0; index < formShapes2.length; index++) {
        let formKey = formShapes3[index][0][0].slice(5);
        let formValue = formShapes3[index][0][1];
        let obj = {};

        obj["key"] = formKey;
        obj["value"] = formValue;
        arr.push(obj);
      }

      this.filterBody["form_shape"] = arr;

      /* --------------- END OF GETTING FORM SHAPES PARAMS FROM URL --------------- */
      this.sortBy = params.sortBy ? params.sortBy : "";
      this.colors = params.color ? params.color.split(",") : [];
      this.size = params.size ? params.size.split(",") : [];

      /* -------------------------------------------------------------------------- */
      /*                   MAKING THE BODY FOR PRICES MULTISELECT                   */
      /* -------------------------------------------------------------------------- */

      this.prices = params.prices?.length > 0 ? params.prices.split(",") : [];
      this.filterTypes =
        params.filter_type?.length > 0 ? params.filter_type.split(",") : [];
      this.brands = params.brandId?.length > 0 ? params.brandId.split(",") : [];
      // console.log(this.brands);

      this.pricesBody["searchMultiPrices"] = [];
      let testArr2 = [];

      for (let index = 0; index < this.prices.length; index++) {
        var splitted = this.prices[index].split("-");
        let priceElementObj = { from: splitted[0], to: splitted[1] };
        testArr2.push(priceElementObj);
      }

      this.filterBody["searchMultiPrices"] = testArr2;
      this.filterPricesNamesButtons = this.filterBody["searchMultiPrices"];

      if (this.filterDataBrand.length > 0) {
        this.filterBrandsNamesButtons = [];
        let options = this.filterDataBrand.map((ele) => ele.options);
        options = options[0];
        for (let i = 0; i < this.brands.length; i++) {
          let index = options.findIndex(
            (option) => option.value == this.brands[i]
          );
          if (index > -1) {
            this.filterBrandsNamesButtons.push({
              value: options[index]["value"],
              label: options[index]["label"],
            });
          }
        }
      }

      /* -------------- END OF MAKING THE BODY FOR PRICES MULTISELECT ------------- */

      this.tags = [...this.brands];

      // this.tags = [...this.brands, ...this.colors, ...this.size];
      // All Tags Array

      this.getCategoryData();
      // this.getFiltersOfProducts();

      // console.log("THIS ARE THE BRANDS", this.brands);

      this.category = params.category ? params.category : null;

      // Get Filtered Products..
      this.productService.filterProducts(this.tags).subscribe((response) => {
        // All Products
        this.all_products = response;

        // console.log(this.all_products);
        // Sorting Filter
        this.all_products = this.productService.sortProducts(
          response,
          this.sortBy
        );

        // // Category Filter
        // if (params.category)
        //   this.all_products = this.all_products.filter(
        //     (item) => item.type == this.category
        //   );

        // // Price Filter
        // this.all_products = this.all_products.filter(
        //   (item) => item.price >= this.minPrice && item.price <= this.maxPrice
        // );

        // this.addItems();
      });
    });
  }

  async ngOnInit() {
    // localStorage.setItem("categories_ids", this);
    //this.getCategoryData();
    /* -------------------------------------------------------------------------- */
    /*            MODIFY sortBy VARIABLE TO BE ADJUSTED WITH API PARAM            */
    /* -------------------------------------------------------------------------- */
  }
  closeTemplateSheetMenu() {
    this.commonService.noteOn = false;
    this.bottomSheet.dismiss();
  }

  // CHECK IF PRICES EXIST IN URL CHECKBOX
  pricesExists(value) {
    let price1 = value.split(",")[0] + "-" + value.split(",")[1];
    return this.prices.includes(price1) ? true : false;
  }

  // CHECK IF PRICES EXIST IN URL CHECKBOX
  categoriesExists(id) {
    return this.sub_categories.includes(id) ? true : false;
  }

  // CHECK IF PRICES EXIST IN URL CHECKBOX
  typesExists(value) {
    return this.filterTypes.includes(value) ? true : false;
  }

  // CHECK IF BRAND EXISTS IN URL PARAMS
  brandsExists(value) {
    return this.brands.includes(value) ? true : false;
  }

  // CHECK IF FORM EXISTS IN URL PARAMS
  formShapeExists(formShapeId: any, optionShapeValue: any) {
    let string = "form_" + formShapeId;
    if (this.formsIdParams[string]) {
      let x = this.formsIdParams[string].indexOf(optionShapeValue) > -1;
      return x;
    } else {
      return false;
    }
  }

  // CHECK BOX CHECK VALUE FOR PRICE FILTER
  checkValuePrice(event: any, value) {
    let price = value.split(",");
    price = price[0] + "-" + price[1];
    if (event.currentTarget.checked) {
      this.prices.push(price);
    } else {
      this.prices.forEach((element, index) => {
        if (element == price) this.prices.splice(index, 1);
      });
    }

    let obj2 = {};
    let string1 = this.prices.toString();
    obj2 = { prices: string1 };

    // DELETE URL PRICE PARAM IF NO PRICE RANGE SELECTED
    if (obj2["prices"] == "") {
      obj2["prices"] = [];
    }

    this.updateFilter(obj2);
  }

  // CHECK BOX CHECK VALUE FOR PRICE FILTER
  checkValueType(event: any, value) {
    let filterType = value.split(",");
    if (event.currentTarget.checked) {
      this.filterTypes.push(filterType);
    } else {
      this.filterTypes.forEach((element, index) => {
        if (element == filterType) this.filterTypes.splice(index, 1);
      });
    }

    let obj2 = {};
    let string1 = this.filterTypes.toString();
    obj2 = { filter_type: string1 };

    // DELETE URL PRICE PARAM IF NO PRICE RANGE SELECTED
    if (obj2["filter_type"] == "") {
      obj2["filter_type"] = [];
    }

    this.updateFilter(obj2);
  }

  // CHECK BOX CHECK VALUE FOR BRANDS
  checkValueBrand(event: any, value, label) {
    if (event.currentTarget.checked) {
      this.brands.push(value);
      this.filterBrandsNamesButtons.push({ value: value, label: label });
    } else {
      this.brands.forEach((element, index) => {
        if (element == value) this.brands.splice(index, 1);
      });

      this.filterBrandsNamesButtons.forEach((element, index) => {
        if (element == value) this.filterBrandsNamesButtons.splice(index, 1);
      });
    }

    let obj2 = {};
    let string1 = this.brands.toString();
    obj2 = { brandId: string1 };

    // DELETE URL PRICE PARAM IF NO PRICE RANGE SELECTED
    if (obj2["brandId"] == "") {
      obj2["brandId"] = [];
    }

    this.updateFilter(obj2);
  }

  // Update Filter Button
  updateFilter1() {
    for (const key in this.formsIdParams) {
      const value = this.formsIdParams[key];
      let string = this.formsIdParams[key].toString();
      this.formsIdParams[key] = string;

      if (this.formsIdParams[key] == "") {
        delete this.formsIdParams[key];
      }
    }
    this.updateFilter(this.formsIdParams);
    // console.log("LASST", this.formsIdParams);

    this.formsIdParams = {};
    // let string = this.formsIdParams["form_1787"].toString();
    // this.formsIdParams["form_1787"] = string
    // console.log("LASST", this.formsIdParams);
  }

  // CHECK BOX CHECK VALUE FOR FORMS
  checkValueForm(event: any, formShapeId: any, optionShapeId: any) {
    // console.log("start of check value", this.formsIdParams);

    console.log(event, formShapeId, optionShapeId);
    let formId = "form_" + formShapeId;
    // this.formsIdParams[formId].push(optionShapeId);
    // console.log("this is option shape id", optionShapeId);

    if (event.currentTarget.checked) {
      this.formsIdParams[formId].push(optionShapeId);
    } else {
      this.formsIdParams[formId].forEach((element, index) => {
        if (element == optionShapeId)
          this.formsIdParams[formId].splice(index, 1);
      });
    }

    for (const key in this.formsIdParams) {
      const value = this.formsIdParams[key];

      if (typeof value === "string" || value instanceof String) {
        // console.log("THE ARRAY IS A STRING I CAN'T CONVERT IT TO STRING");
        break;
      }
      if (Array.isArray(value)) {
        let string = this.formsIdParams[key].toString();
        this.formsIdParams[key] = string;

        // console.log("Mid CHECKING VALYE", this.formsIdParams);

        if (this.formsIdParams[key] == "") {
          this.formsIdParams[key] = [];
          // delete this.formsIdParams[key];
        }
      }
    }
    // console.log("SEARCH", this.formsIdParams);

    this.updateFilter(this.formsIdParams);
    // this.formsIdParams = {};

    // console.log("END OF CHECK VALUE", this.formsIdParams);
  }

  // REMOVE FORM TAG
  removeFormTag(value: any, formId1: any) {
    // console.log("FORM 4 GRAND CHECK", this.formsIdParams);

    let formId = "form_" + formId1;
    this.formsIdParams[formId].forEach((element, index) => {
      if (element == value) this.formsIdParams[formId].splice(index, 1);
    });

    for (const key in this.formsIdParams) {
      const value = this.formsIdParams[key];

      if (typeof value === "string" || value instanceof String) {
        // console.log("THE ARRAY IS A STRING I CAN'T CONVERT IT TO STRING");
        break;
      }
      if (Array.isArray(value)) {
        let string = this.formsIdParams[key].toString();
        this.formsIdParams[key] = string;

        // console.log("Mid CHECKING VALYE", this.formsIdParams);

        if (this.formsIdParams[key] == "") {
          this.formsIdParams[key] = [];
          // delete this.formsIdParams[key];
        }
      }
    }
    // console.log("FORM 4 GRAND CHECK", this.formsIdParams);
    this.updateFilter(this.formsIdParams);

    // console.log("FORM 4 GRAND CHECK",this.formsIdParams);
  }

  // REMOVE BRAND TAG
  removeBrandTag(value: any) {
    this.brands.forEach((element, index) => {
      if (element == value) this.brands.splice(index, 1);
    });

    // this.filterBrandsNamesButtons.forEach((element, index) => {
    //   if (element.value == value) this.filterBrandsNamesButtons.splice(index, 1);
    // });

    let obj2 = {};
    let string1 = this.brands.toString();
    obj2 = { brandId: string1 };

    if (obj2["brandId"] == "") {
      obj2["brandId"] = [];
    }
    this.updateFilter(obj2);
  }

  // REMOVE PRICE TAG
  removePriceTag(from: any, to: any) {
    let singlePrice = from + "-" + to;

    this.prices.forEach((element, index) => {
      if (element == singlePrice) this.prices.splice(index, 1);
    });

    let obj2 = {};
    let string1 = this.prices.toString();
    obj2 = { prices: string1 };

    if (obj2["prices"] == "") {
      obj2["prices"] = [];
    }
    this.updateFilter(obj2);
  }

  removeFilterTypeTag(value) {
    this.filterTypes.forEach((element, index) => {
      if (element == value) this.filterTypes.splice(index, 1);
    });

    let obj2 = {};
    let string1 = this.filterTypes.toString();
    obj2 = { filter_type: string1 };

    if (obj2["filter_type"] == "") {
      obj2["filter_type"] = [];
    }
    this.updateFilter(obj2);
  }

  getFiltersOfProducts(newCategories = "") {
    // if (this.start == 0) {
    //   this.allShownProducts = [];
    // }
    this.route.params.subscribe((params: ParamMap) => {
      const category = params["slug"];

      this.breadCrumbList.forEach((ele) => {
        if (ele.id == category && ele.parent != "0") {
          this.parent_id = ele.parent;
        } else {
          this.parent_id = ele.id;
        }
      });
      // console.log("breadCrumbList", this.breadCrumbList)

      this.productService
        .getFiltersOfProducts(
          this.parent_id == undefined ? newCategories : this.parent_id,
          ""
        )
        .subscribe((data) => {
          // console.log(data);

          let allDataFilters: any = data["data"];
          this.filterDataShapes = allDataFilters.filter((ele) => ele.id);
          this.filterDataPrice = allDataFilters.filter(
            (ele) => ele.mode === "price"
          );
          this.filterDataType = allDataFilters.filter(
            (ele) => ele.mode === "availability_status"
          );
          this.filterDataBrand = allDataFilters.filter(
            (ele) => ele.mode === "trademark"
          );
          this.filterBrandsNamesButtons = [];
          // Initialize Brand Buttons
          let options = this.filterDataBrand.map((ele) => ele.options);
          options = options[0];
          for (let i = 0; i < this.brands.length; i++) {
            let index = options.findIndex(
              (option) => option.value == this.brands[i]
            );
            if (index > -1) {
              this.filterBrandsNamesButtons.push({
                value: options[index]["value"],
                label: options[index]["label"],
              });
            }
          }

          /************* OLD VERSION **************/
          // this.filterDataShapes = data["data"]["form_shapes"];
          // this.filterDataPrice = data["data"]["prices"];
          // this.filterDataBrand = data["data"]["trademarks"];
          /************* OLD VERSION **************/

          // GET FORMS OPTIONS NAMES
          let arr1 = [];
          for (const key in this.formShapes4) {
            const value = this.formShapes4[key];
            // console.log("form 4 value", value);
            let string = key.substring(5);
            // console.log("form 4 key", string);

            let object1 = { id: string, options: value };
            arr1.push(object1);
          }

          // console.log("form 4 arr1", arr1);
          // console.log("form 4", this.formShapes4);
          // console.log("form 4", this.filterDataShapes);

          let result = this.filterDataShapes.filter((o1) => {
            return arr1.some((o2) => o1.id === o2.id);
          });

          // console.log("form 4 arr result", result);

          let result_1 = result;
          let arr1_1 = arr1.map((obj) => obj.options);
          // console.log("form 4 result_1", result_1);
          var labels = [];
          // console.log("form 4 arr1_1", arr1_1);

          this.filterFormsNamesButtons = [];
          for (var i = 0; i < result_1.length; ++i) {
            // console.log("form 4 grand", result_1[i]);

            let options1 = result_1[i]["options"];
            for (let k = 0; k < options1.length; k++) {
              for (var j = 0; j < arr1_1.length; ++j) {
                for (let m = 0; m < arr1_1[j].length; m++) {
                  // console.log("form 4 grand", arr1_1[j][m]);
                  let x = options1[k]["value"];
                  let y = arr1_1[j][m];
                  if (x == y) {
                    // If element is in both the arrays
                    // this.filterFormsNamesButtons.push(options1[k]); // Push to common array
                    let formIdTest = result_1[i]["id"];
                    options1[k]["formId"] = formIdTest;
                    this.filterFormsNamesButtons.push(options1[k]); // Push to common array
                  }
                }
              }
            }
          }
          // console.log(this.filterFormsNamesButtons);

          // console.log("FORM 4 GRAND", this.filterFormsNamesButtons);

          // FIND COMMON ELEMENTS BETWEEN BRANDS FROM API AND BRANDS PARAMS URL

          // CREATE NUMBER OF ARRAYS BASED ON NUMBER OF FORM SHAPES COMING FROM API
          for (let index = 0; index < this.filterDataShapes.length; index++) {
            let formKey = "form_" + this.filterDataShapes[index]["id"];
            if ((this.formsIdParams[formKey] = this.formShapes4[formKey])) {
              this.formsIdParams[formKey] = this.formShapes4[formKey];
            } else {
              this.formsIdParams[formKey] = [];
            }
          }
        });
    });
    // GET FILTERED BRANDS OR CATEGORY
  }

  /* -------------------------------------------------------------------------- */
  /*                         GET TRADEMARK DETAILS INFO                         */
  /* -------------------------------------------------------------------------- */
  getCategoryInfo(categoryId: any) {
    if (categoryId > 0) {
      this.categoriesService
        .getCategoryDescription(categoryId)
        .subscribe((data) => {
          if (data.success) {
            const brandDescription = data.data[0][0].ser_description;
            this.commonService.setMeta(
              "description",
              brandDescription?.replace(/<[^>]*>/g, "")
            );
            this.commonService.setMeta(
              "",
              brandDescription?.replace(/<[^>]*>/g, ""),
              true,
              "og:description"
            );
          }
        });
    }
  }

  /* -------------------- END OF GET TRADEMARK DETAILS INFO ------------------- */
  offernamebool = false;
  offername = "";
  getCategoryData() {
    this.offernamebool = false;
    this.offername = "";
    this.loading = true;
    this.finished = false;
    this.route.params.subscribe(async (params: ParamMap) => {
      this.loading = true;
      this.finished = false;
      this.category_id = params["slug"];
      this.categoriesService
        .getServiceInfo(this.category_id)
        .subscribe(async (data) => {
          if (data.data) {
            this.category_name = data.data.ser_name;
            if (this.category_name !== this.categoryRouterName) {
              this.filterLoading = true;
            }
            this.getData();
          } else {
            if (
              params["name"] == "best_seller" ||
              params["name"] == "new" ||
              params["name"] == "form_shapes" ||
              params["name"] == "selected_products" ||
              params["name"] == "promo" 
            ) {
              if (params["offername"]) {
                this.offernamebool = true;
                this.offername = params["offername"].split("-").join(" ");
              }
              this.category_name = params["name"].split("-").join(" ");
              if (this.category_name !== this.categoryRouterName) {
                this.filterLoading = true;
              }
              this.getData();
            } else {
              this.router.navigate(["/"]);
            }
          }
        });
    });
  }
  async getData() {
    let type = "";
    let sort = "";
    let prod_ids = [];
    if (this.route.snapshot.url[1]?.path == "selected_products") {
      this.route.queryParams.subscribe((params) => {
        const prodIdsString = params["prod_ids"];
        if (prodIdsString) {
          prod_ids = prodIdsString.split(",").map((id) => id);
        }
        console.log("Product IDs:", prod_ids);
      });
    }
    this.filterBody["prod_ids"] = prod_ids;
    switch (this.sortBy) {
      case "a-z":
        type = "ASC";
        sort = "name";
        break;

      case "z-a":
        type = "DESC";
        sort = "name";
        break;
      case "low":
        type = "ASC";
        sort = "price";
        break;
      case "high":
        type = "DESC";
        sort = "price";
        break;
      default:
        break;
    }

    if (this.category_id > 0) {
      this.categoriesService
        .getBreadcrumb(this.category_id)
        .subscribe((data) => {
          this.breadCrumbList = data.data;

          this.breadCrumbList.forEach((ele) => {
            if (ele.id == this.category_id && ele.parent != "0") {
              this.parent_id = ele.parent;
            } else {
              this.parent_id = ele.id;
            }
          });
        });
      this.getCategoryInfo(this.category_id);
    }
    setTimeout(() => {
      this.commonService.setTitle(this.category_name, false, false, "inifinte");
    }, 1000);
    this.loading = true;
    this.finished = false;
    this.getNoData = false;
    const brandsString = this.brands.join(",");
    this.productService
      .getProductsPayParams(
        this.category_id > 0 ? this.category_id : "",
        "",
        brandsString,
        "",
        this.filterBody,
        this.start,
        this.limit,
        sort,
        type,
        this.filterTypes.join(","),
        "",
        !(this.category_id > 0) ? this.category_name : ""
      )
      .subscribe((products) => {
        if (products.data) {
          if (this.start == 0) {
            this.allItems = [];
            this.allShownProducts = [];
            this.fetchedIds.clear();
          }

          products.data.forEach((product) => {
            if (!this.fetchedIds.has(product.id)) {
              this.fetchedIds.add(product.id);
              this.allItems.push(product);
            }
          });
          this.all_products = this.allItems;
          this.allShownProducts = this.allItems;
          this.loading = false;
          this.finished = true;

          if (this.allItems.length == 0) {
            this.getNoData = true;
          }
        } else {
          this.getNoData = true;
          this.loading = false;
          this.allItems = [];
          this.finished = true;
        }
      });

    const id = this.route.snapshot.url[1]?.path;
    const name =
      this.route.snapshot.url[1]?.path == "form_shapes" || "selected_products"
        ? undefined
        : this.route.snapshot.url[2]?.path;
    console.log(this.route.snapshot.url);
    let body = {
      form_shape: [],
      searchMultiPrices: [],
    };

    if (this.categoryRouterName !== id) {
      this.categoryRouterName = id;
      const data = await this.productService
        .getSearchProductCount(
          name != undefined ? id : "",
          "",
          brandsString,
          "",
          body,
          this.start,
          this.limit,
          "",
          "",
          [],
          "",
          !(name != undefined) ? id : ""
        )
        .toPromise();
      this.categoriesIds = data.all_category_ids_for_searching_products;
    }
    this.filterLoading = false;
    this.getFiltersOfProducts(this.categoriesIds);
  }

  addItems() {
    if (
      this.all_products.length <= this.products.length &&
      this.all_products.length <= this.addItemCount
    ) {
      this.finished = true;
      return;
    }
    this.products = this.all_products.slice(0, this.addItemCount);
  }

  // Infinite scroll
  public onScroll() {
    // this.addItemCount += 8;
    // this.addItems();

    this.start += this.limit;
    this.getCategoryData();
  }

  // Append filter value to Url
  updateFilter(tags: any) {
    this.allShownProducts = [];
    this.start = 0;

    tags.page = null; // Reset Pagination
    this.router
      .navigate([], {
        relativeTo: this.route,
        queryParams: tags,
        queryParamsHandling: "merge", // preserve the existing query params in the route
        skipLocationChange: false, // do trigger navigation
      })
      .finally(() => {
        this.viewScroller.setOffset([120, 120]);
        this.viewScroller.scrollToAnchor("products"); // Anchore Link
      });
  }

  // SortBy Filter
  sortByFilter(value) {
    this.router
      .navigate([], {
        relativeTo: this.route,
        queryParams: { sortBy: value ? value : null },
        queryParamsHandling: "merge", // preserve the existing query params in the route
        skipLocationChange: false, // do trigger navigation
      })
      .finally(() => {
        this.viewScroller.setOffset([120, 120]);
        this.viewScroller.scrollToAnchor("products"); // Anchore Link
      });
  }

  // Remove Tag
  removeTag(tag) {
    this.brands = this.brands.filter((val) => val !== tag);
    this.colors = this.colors.filter((val) => val !== tag);
    this.size = this.size.filter((val) => val !== tag);

    let params = {
      brand: this.brands.length ? this.brands.join(",") : null,
      color: this.colors.length ? this.colors.join(",") : null,
      size: this.size.length ? this.size.join(",") : null,
    };

    this.router
      .navigate([], {
        relativeTo: this.route,
        queryParams: params,
        queryParamsHandling: "merge", // preserve the existing query params in the route
        skipLocationChange: false, // do trigger navigation
      })
      .finally(() => {
        this.viewScroller.setOffset([120, 120]);
        this.viewScroller.scrollToAnchor("products"); // Anchore Link
      });
  }

  // Clear Tags
  removeAllTags() {
    // RESET
    this.start = 0;

    this.router
      .navigate([], {
        relativeTo: this.route,
        queryParams: {},
        skipLocationChange: false, // do trigger navigation
      })
      .finally(() => {
        this.viewScroller.setOffset([120, 120]);
        this.viewScroller.scrollToAnchor("products"); // Anchore Link
      });
  }

  // Mobile sidebar
  toggleMobileSidebar() {
    console.log("Toggle Mobile Sidebar");
    this.mobileSidebar = !this.mobileSidebar;
  }
}
