<div class="navbar">
  <button class="border-0 btn" (click)="leftMenuToggle()" aria-label="MenuToggle">
    <div class="bar-style"><i class="fa fa-bars sidebar-bar" aria-hidden="true"></i></div>
  </button>
  <div id="mySidenav" class="sidenav" [style.direction]="Commen._lang_code == 'ar' ?'rtl' :'ltr'"
    [class.openSide]="navServices?.leftMenuToggle">
    <button class="sidebar-overlay" (click)="leftMenuToggle()" (mouseover)="onHover(false)"></button>
    <nav id="unset">
      <div class="d-flex align-items-center justify-content-between">
        <div (click)="leftMenuToggle()" class="">
          <div class="mt-3 text-left sidebar-back d-flex align-items-center justify-content-center"><i class="pr-2 fa fa-angle-left" aria-hidden="true"></i> <span>{{ 'Back' |
            translate }}</span>
          </div>
        </div>
        <div class="mx-3" *ngIf="AllLangs?.length > 1">
          <div class="header-contact">
            <ul class="d-flex">
                <li class="onhover-dropdown" >
                        <span *ngIf="site_lang"
                        class="d-flex justify-content-center align-items-center cursor">
                        <img class="mx-2 flag"
                            [src]="langImgUrl + site_lang?.lang_image"
                            alt="arabic" width="24px" height="24px">
                        <span>
                            {{ site_lang?.name | translate}}
                        </span>
                    </span>
                    <ul class="onhover-show-div">
                        <li *ngFor="let lang of AllLangs">
                            <button class="cursor btn-lang" data-lng="ar" (click)="changeLanguage(lang.code)">
                                <img class="flag"
                                    [src]="langImgUrl + lang.lang_image"
                                    alt="arabic">
                                <span class="mx-2">{{lang.name | translate}}
                                </span>
                            </button>
                        </li>

                    </ul>
                </li>

            </ul>
        </div>
        
        </div>
      </div>
      <ul id="sub-menu" class="sidebar-menu">
        <li *ngFor="let menuItem of menuItems" (mouseover)="onHover(menuItem.children ? true : false)">
          <!-- Sub -->
          <div class="px-1 d-flex justify-content-between align-items-center">
            <button *ngIf="menuItem.type === 'sub'"  class="menuItem" (click)="toggletNavActive(menuItem)">
              {{menuItem.title}}
            </button>
            <span class="sub-arrow" *ngIf="menuItem.children" ></span>
          </div>
          <!-- Link -->
          <a [routerLink]="menuItem.path" *ngIf="menuItem.type === 'link'">
            {{menuItem.title}}
            <span class="sub-arrow" *ngIf="menuItem.children"></span>
          </a>
          <!-- External Link -->
          <a href="{{ menuItem.path }}" *ngIf="menuItem.type === 'extLink'">
            {{menuItem.title}}
            <span class="sub-arrow" *ngIf="menuItem.children"></span>
          </a>
          <!-- External Tab Link -->
          <a href="{{ menuItem.path }}" *ngIf="menuItem.type === 'extTabLink'">
            {{menuItem.title}}
            <span class="sub-arrow" *ngIf="menuItem.children"></span>
          </a>
  
          <!-- 2nd Level Menu -->
          <ul class="nav-submenu" [class]="menuItem.megaMenu ? 'mega-menu clothing-menu' : ''" [class.opensub1]="menuItem.active"
          *ngIf="menuItem.children">
              <app-nav-menu-item [menuItem]="menuItem"></app-nav-menu-item>
          </ul>
        </li>
        <li>
          <a href="" *ngIf="userLogedIn" (click)="userLogOut()">
            {{'logout' | translate}}
          </a>
        </li>
      </ul>

    </nav>
  </div>
</div>

<!-- login -->
<section #login class="full-sidebar" *ngIf="loginclicked">
  <div class="shadow login-sidebar">
    <i class="ti-close login-icon" (click)="close()"></i>
    <app-fast-login></app-fast-login>
  </div>
</section>