import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class KnetPaymentService {
  private knetPaymentUrl = '/knet-api/PaymentHTTP.htm?param=paymentInit'; // KNET test URL

  constructor(private http: HttpClient) {}

  initializePayment(data: any) {
    const requestBody = this.constructRequestPayload(data);
    return this.http.post(this.knetPaymentUrl, requestBody, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      responseType: 'text',
    });
  }

  private constructRequestPayload(data: any) {
    const params = new URLSearchParams();
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        params.append(key, data[key]);
      }
    }
    return params.toString();  
  }
}
