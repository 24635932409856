import { Component, Input, OnInit } from '@angular/core';
import { Menu } from '../../services/nav.service';

@Component({
  selector: 'app-nav-menu-item',
  templateUrl: './nav-menu-item.component.html',
  styleUrls: ['./nav-menu-item.component.scss']
})
export class NavMenuItemComponent implements OnInit {

  @Input() menuItem: any
  constructor() { }

  ngOnInit(): void {
    // console.log("this.menuItem", this.menuItem);
    
  }

  toggletNavActive(item: any , e:Event) {
    e.stopPropagation();
    e.preventDefault(); 
    item.active = !item.active;
  }
}
