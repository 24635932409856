<div class="main-navbar">
  <div id="mainnav">
    <div class="toggle-nav" (click)="mainMenuToggle()">
      <i class="fa fa-bars sidebar-bar"></i>
    </div>
    <ul class="nav-menu" [class.opennav]="navServices?.mainMenuToggle">

      <li class="back-btn">
        <div class="text-right mobile-back" (click)="mainMenuToggle()">
          <span> {{ 'Back' | translate }} </span>
          <i class="pl-2 fa fa-angle-right" aria-hidden="true"></i>
        </div>
      </li>

      <li *ngFor="let menuItem of menuItems" [class.mega-menu]="menuItem.megaMenu">
        <!-- Sub -->
        <button  class="nav-link" *ngIf="menuItem.type === 'sub'" 
            (click)="toggletNavActive(menuItem)">
          {{ menuItem.title | translate }}
          <div class="lable-nav" *ngIf="menuItem.badge">{{ menuItem.badgeText | translate }}</div>
          <span class="sub-arrow" *ngIf="menuItem.children"></span>
        </button>
        <!-- Link -->
        <a [routerLink]="menuItem.path" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link"
          *ngIf="menuItem.type === 'link'">
          {{ menuItem.title }}
          <div class="lable-nav" *ngIf="menuItem.badge"></div>
          <span class="sub-arrow" *ngIf="menuItem.children"></span>
        </a>
        <!-- External Link -->
        <a href="{{ menuItem.path }}" class="nav-link" *ngIf="menuItem.type === 'extLink'">
          {{ menuItem.title }}
          <div class="lable-nav" *ngIf="menuItem.badge">{{ menuItem.badgeText | translate }}</div>
          <span class="sub-arrow" *ngIf="menuItem.children"></span>
        </a>
        <!-- External Tab Link -->
        <a href="{{ menuItem.path }}" class="nav-link" *ngIf="menuItem.type === 'extTabLink'">
          {{ menuItem.title }}
          <div class="lable-nav" *ngIf="menuItem.badge">{{ menuItem.badgeText | translate }}</div>
          <span class="sub-arrow" *ngIf="menuItem.children"></span>
        </a>

        <!-- 2nd Level Menu -->
        <ul class="nav-submenu" [class.opensubmenu]="menuItem.active" *ngIf="menuItem.children">
            <app-nav-menu-item [menuItem]="menuItem"></app-nav-menu-item>
        </ul>
      </li>


    </ul>
  </div>
</div>
