import {
  Component,
  OnDestroy,
  Inject,
  OnInit,
  PLATFORM_ID,
  TemplateRef,
  ViewChild,
  HostListener,
} from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { ProductService } from "../../shared/services/product.service";
import { Product } from "../../shared/classes/product";
import { CommonService } from "../../shared/services/common.service";
import { ValueTransformer } from "@angular/compiler/src/util";
import { ToastrService } from "ngx-toastr";
import { isPlatformBrowser } from "@angular/common";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { OrderService } from "src/app/shared/services/order.service";
import { CouponesService } from "src/app/shared/services/coupones.service";
import { NavigationEnd, Router } from "@angular/router";
import { exit } from "process";
import { filter, take } from "rxjs/operators";
import Swal from "sweetalert2";
import { MasterApiService } from "src/app/shared/services/master-api.service";
declare var jquery: any;
declare var $: any;
@Component({
  selector: "app-cart",
  templateUrl: "./cart.component.html",
  styleUrls: ["./cart.component.scss"],
})
export class CartComponent implements OnInit, OnDestroy {
  panelOpenState = false;
  public urlimage = "";
  public importApiImage = "";
  public products: Product[] = [];
  public cart_products: Product = {} as Product;
  public cart_products_total_price = "";
  public cart_products_total_price_final: any;
  public countItems = "0";
  public totalDiscounts = "0";
  public disabledplus: boolean = false;
  public disabledminus: boolean = false;
  public editOrderId: any;

  // MODAL CANCEL INVOICE STEPS
  step1: boolean = false;
  step2: boolean = false;
  step3: boolean = false;
  cancelInvoiceId = "";
  @ViewChild("newsletter", { static: false }) NewsLetterModal: TemplateRef<any>;
  public modalOpen: boolean = false;
  public closeResult: string;

  page_name = localStorage.getItem("front-lang") == "ar" ? "السلة" : "Cart";

  showCouponsList: boolean = false;
  activeCouponesList: any[] = [];
  couponesAccordionList: any[] = [];
  codeValueCoupon: any;
  cartId: any;
  isCouponAdded: boolean = false;
  couponId: any;
  public couponDiscount: any;
  currentRoute: any;
  loading = true;
  loadingQuantity = false;
  subscription: Subscription;
  noteTitle: any;
  contactInfo: any;
  quantity_in_cart: number = 1;
  cartInfoDetailsLoading = false;

  constructor(
    public productService: ProductService,
    private orderService: OrderService,
    private toastrService: ToastrService,
    public Common: CommonService,
    private modalService: NgbModal,
    private couponesService: CouponesService,
    private router: Router,
    private MasterApiService: MasterApiService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    // SET TITLE
    this.Common.setTitle(this.page_name);

    this.productService.cartItems.subscribe(
      (response) => (this.products = response)
    );
    this.urlimage = this.Common._ImageUrl + "products/";
    this.importApiImage = this.Common._ImageUrl + "importapi/";
    this.editOrderId = localStorage.getItem("order-edit-id");
    //this.currentRoute = this.router.url.split("/")[2];

    this.subscription = this.Common.UpdatedCart.subscribe({
      next: (res) => {
        //console.log(res);
        this.loading = true;
        this.getCartItemsFromServer();
      },
    });

    //console.log"ORDER EDIT ID", this.editOrderId);
  }

  ngOnInit() {
    //this.getCartItemsFromServer();
    this.getCouponsListAll();
    this.MasterApiService.getCurrentDataContact().subscribe(
      (dataContact) => {
        if (!dataContact) {
          console.error("Data contact information is undefined or null");
          return;
        }
        this.contactInfo = dataContact;
      },
      (error) => {
        console.error("Error fetching contact info", error);
      }
    );
  }
  addCoupon(codeCoupon = this.codeValueCoupon, cartId) {
    this.cartInfoDetailsLoading = true;
    this.couponesService
      .addUseCoupon(codeCoupon, "cart", cartId)
      .subscribe((data) => {
        //console.log"add coupon ", data);
        if (data.success) {
          let msg1 =
            localStorage.getItem("front-lang") == "ar"
              ? "تم إضافة الكوبون بنجاح"
              : "Coupon Added Successfully";
          this.toastrService.success(msg1);
          this.isCouponAdded = true;
          this.couponId = data.data.id;
          //console.log"add coupon ", this.couponId);
          this.Common.UpdatedSetting.next("decrease-cost");
          this.getCartItemsFromServer("load");
        } else {
          this.toastrService.error(data.message);
          this.cartInfoDetailsLoading = false;
        }
      });
  }

  deleteCoupon(cartId, deleteCart = "") {
    this.cartInfoDetailsLoading = true;
    this.couponesService
      .deleteUseCoupon(this.couponId, this.cartId)
      .subscribe((data) => {
        //console.log"delete coupon ", data);

        if (data.success) {
          if (deleteCart != "delete-cart") {
            this.toastrService.warning(data.message);
          }
          this.toastrService.warning(data.message);
          this.isCouponAdded = false;
          this.codeValueCoupon = "";
          this.Common.UpdatedSetting.next("increase-cost");
          this.couponId = "";
          this.getCartItemsFromServer("load");
        } else {
          this.toastrService.error(data.message);
          this.cartInfoDetailsLoading = false;
        }
      });
  }

  confirmCouponToInput(couponCode: any, cartId: any) {
    this.cartInfoDetailsLoading = true;
    if (!this.isCouponAdded) {
      // CAUTION THIS CODE SHOULD BE AS 'addCoupon' FUNCTION BE AWARE
      console.log("id", cartId);
      this.couponesService
        .addUseCoupon(couponCode, "cart", cartId)
        .subscribe((data) => {
          //console.log"add coupon ", data);
          if (data.success) {
            this.codeValueCoupon = couponCode;
            let msg1 =
              localStorage.getItem("front-lang") == "ar"
                ? "تم إضافة الكوبون بنجاح"
                : "Coupon Added Successfully";
            this.toastrService.success(msg1);
            this.isCouponAdded = true;
            this.couponId = data.data.id;
            //console.log"add coupon ", this.couponId);
            this.Common.UpdatedSetting.next("decrease-cost");
            this.getCartItemsFromServer("load");
          } else {
            this.toastrService.error(data.message);
            this.cartInfoDetailsLoading = false;

          }
        });
    } else {
      let msg2 =
        localStorage.getItem("front-lang") == "ar"
          ? "يرجى حذف الكوبون المفعل من السلة."
          : "Please Delete The Activated Coupon From Cart";
      this.toastrService.error(msg2);
      this.cartInfoDetailsLoading = false;
    }
  }

  openCouponeDescription(index: any) {
    //console.log"open coupone description ", index);
    this.couponesAccordionList[index] = !this.couponesAccordionList[index];
    //console.log"All Coupones accordion list", this.couponesAccordionList);
  }

  getCouponeAccordionStatus(index: any) {
    if (this.couponesAccordionList[index]) {
      return true;
    } else {
      return false;
    }
  }

  getCouponsListAll() {
    // this.finished = false;
    this.couponesService.getCouponsList("all").subscribe((data) => {
      // this.finished = true;
      // console.log("All Coupones", data);
      if (data.success) {
        this.activeCouponesList = data.data;

        for (let index = 0; index < this.activeCouponesList.length; index++) {
          const element = this.activeCouponesList[index];
          let accordionStatus: boolean = false;
          this.couponesAccordionList.push(accordionStatus);
        }
        //console.log"All Coupones accordion list", this.couponesAccordionList);
      } else {
        //console.log"Error Loading Coupones");
      }
    });
  }
  removeBrackets(str: string): string {
    return str.replace(/[\()%/]/g, "");
  }
  cancelInvoice(invoiceId: any) {
    this.orderService.cancelInvoice(invoiceId).subscribe((data) => {
      let cancelInvoiceData = data;
      if (cancelInvoiceData.success) {
        this.modalService.dismissAll();
        this.toastrService.success(cancelInvoiceData.message);
        this.getCartItemsFromServer();
      } else {
        this.modalService.dismissAll();
        this.toastrService.error(cancelInvoiceData.message);
      }
    });
  }
  onCloseModalDetails(modal: HTMLElement, layer: HTMLElement) {
    modal.style.bottom = "-150%";
    layer.style.display = "none";
  }
  openModalDetails(modal: HTMLElement, layer: HTMLElement) {
    modal.style.bottom = "-10px";
    layer.style.display = "block";
  }

  openModal(invoiceId: any) {
    // this.step1 = false;
    // this.step2 = false;
    // this.step3 = false;
    // this.cancelInvoiceId = "";
    // if (invoiceId != "" && invoiceId > 0) {
    //   this.step1 = true;
    //   this.step2 = false;
    //   this.step3 = false;
    //   this.cancelInvoiceId = invoiceId;
    //   if (isPlatformBrowser(this.platformId)) {
    //     // For SSR
    //     this.modalService
    //       .open(this.NewsLetterModal, {
    //         size: "lg",
    //         ariaLabelledBy: "NewsLetter-Modal",
    //         centered: true,
    //         windowClass: "theme-modal newsletterm NewsLetterModal",
    //       })
    //       .result.then(
    //         (result) => {
    //           this.modalOpen = true;
    //           `Result ${result}`;
    //         },
    //         (reason) => {
    //           this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    //         }
    //       );
    //   }
    // }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  getCartItemsFromServer(loading = '') {
    //this.loading = true;
    this.getNoteDetails(25);
    this.Common.getCart().subscribe((data) => {
      // if (data.data) {
      //   this.loading = false;
      //   this.cart_products = data.data;
      //   this.products = this.cart_products["product_items"];
      //   this.cartId = this.cart_products["id"];
      //   //console.log"cart id", this.cartId);
      //   if (this.products?.length > 0) {
      //     this.cart_products_total_price = this.cart_products["total_price"];
      //     this.countItems = this.cart_products["total_product_count"];
      //     this.totalDiscounts = this.cart_products["total_discount"];

      //     let couponInfoFromServer = this.cart_products["couponInfo"];

      //     // CHECK IF CART HAS ALREADY ACTIVE COUPON
      //     if (couponInfoFromServer) {
      //       //console.log"active coupon from server ", couponInfoFromServer);

      //       this.isCouponAdded = true;
      //       this.couponId = couponInfoFromServer.id;
      //       this.codeValueCoupon = couponInfoFromServer.code;
      //     }
      //     this.couponDiscount = this.cart_products["total_coupon_discount"];
      //     //console.log"CART ALL", this.cart_products);
      //     //console.log"Total coupon discount", this.couponDiscount);

      //     this.cart_products_total_price_final =
      //       parseInt(this.cart_products_total_price) -
      //       parseInt(this.totalDiscounts) -
      //       parseInt(this.couponDiscount);
      //   }
      //   //console.log"CART ITEMS", this.products);
      // } else {
      //   this.loading = false;
      //   //console.log"no data in  getCartItemsFromServer CartComponent");
      // }
      if (data.data) {
        this.cart_products = data.data;
        // console.log(this.cart_products);
        this.products = [];
        if (this.cart_products.product_items.length > 0) {
          this.products = this.cart_products["product_items"];
          this.cart_products.panelOpenState = false;
        }

        if (this.cart_products["couponInfo"]) {
          this.codeValueCoupon =
            this.cart_products["couponInfo"]["code"];
          this.isCouponAdded = true;
          this.couponId = this.cart_products["couponInfo"]["id"];
        }
        this.cart_products["panelOpenState"] = true;

        // this.products = [];
        // let couponInfoFromServer = [];
        // this.cart_products.forEach((ele, index) => {
        //   console.log("invoice_edit_id", ele['invoice_edit_id']);
        //   this.products.push(ele["product_items"]);
        //   this.cartId = ele["id"];
        //   if (this.products?.length > 0) {
        //     // couponInfoFromServer.push(ele["couponInfo"]);
        //     if (couponInfoFromServer) {
        //       this.isCouponAdded = true;
        //       // this.couponId.push(couponInfoFromServer['id']);
        //       // this.codeValueCoupon.push(couponInfoFromServer['code']);
        //     }
        //   }
        // });

        // this.cart_products_total_price = String(this.cart_products.reduce((a, b) => a['total_price'] + b['total_price']));
        // this.totalDiscounts = String(this.cart_products.reduce((a, b) => a["total_discount"] + b["total_discount"]));
        // this.couponDiscount = String(this.cart_products.reduce((a, b) => a["total_coupon_discount"] + b["total_coupon_discount"]));
        // this.countItems = String(this.cart_products.reduce((a, b) => a["total_product_count"] + b["total_product_count"]));

        this.cart_products_total_price_final =
          parseInt(this.cart_products_total_price) -
          parseInt(this.totalDiscounts) -
          parseInt(this.couponDiscount);
        this.loading = false;

        if ((loading = "load")) {
          this.cartInfoDetailsLoading = false;
        }
      } else {
        this.loading = false;
        this.cart_products = {} as Product;
        if ((loading = "load")) {
          this.cartInfoDetailsLoading = false;
        }
        //console.log"no data in  getCartItemsFromServer CartComponent");
      }
    });
  }
  public get getTotal(): Observable<number> {
    return this.productService.cartTotalAmount();
  }

  getDiscountPercentage(last_price: string, price: string) {
    var l_p = parseInt(last_price);
    var p = parseInt(price);
    var res = ((l_p - p) / l_p) * 100;
    return Math.round(res);
  }

  getQuantityDiscountPercentage(qPrice: string, price: string) {
    var q_p = parseInt(qPrice);
    var p = parseInt(price);

    var res = ((q_p - p) / q_p) * 100;
    return res.toFixed(2);
  }

  getSubtraction(last_price: string, price: string, qunt = 1) {
    var l_p = parseInt(last_price);
    var p = parseInt(price);
    var res = (l_p - p) * qunt;
    return Math.round(res);
  }

  // Increament
  increment(product: { [x: string]: any }, qtn, loader) {
    this.disabledplus = true;

    qtn.classList.add("d-none");
    loader.classList.remove("d-none");

    let productMaxQtn = product['mx_quantity'];
    let cartProducts = this.products;
    console.log(cartProducts);
    if (cartProducts.length > 0) {
      let cartProduct = cartProducts.filter((item) => item.id == product.id)[0];
      console.log(cartProduct , cartProducts.length);
      this.quantity_in_cart = cartProduct ? parseFloat(cartProduct.quantity) + 1 : 1; 
    }
    console.log(productMaxQtn , this.quantity_in_cart);
    if (productMaxQtn < this.quantity_in_cart) {
      if (localStorage.getItem("front-lang") == "en") {
        this.toastrService.error("You have exceeded the maximum product limit.");
      } else {
        this.toastrService.error("لقد تجاوزت الحد الاقصى للمنتج");
      }
      this.disabledplus = false;
      qtn.classList.remove("d-none");
      loader.classList.add("d-none");
      return;
    }

    var newquantity = parseInt(product["quantity"]);
    newquantity++;
    if (newquantity <= parseInt(product["store_amounts_product"])) {
      let body = {
        key: product["id"],
        quantity: newquantity,
      };
      this.Common.updateCartItem(body).subscribe((data) => {
        if (data.success) {
          //console.log"updateCartItem to server");
          this.disabledplus = false;
          this.Common.UpdatedSetting.next("increment");
          this.getCartItemsFromServer();
          //this.Common.changeCart("");
          if (localStorage.getItem("front-lang") == "en") {
            this.toastrService.success("Product has been updated in cart.");
          } else {
            this.toastrService.success("تم تحديث المنتج فى السلة ");
          }
          //this.updateQuatnityNum(qtn , loader)
        } else {
          this.disabledplus = false;
          qtn.classList.remove("d-none");
          loader.classList.add("d-none");
        }
      });
    } else {
      if (localStorage.getItem("front-lang") == "en") {
        this.toastrService.error("Product not available");
      } else {
        this.toastrService.error(" المنتح غير متوفر ");
      }
      this.disabledplus = false;
      qtn.classList.remove("d-none");
      loader.classList.add("d-none");
    }
  }

  // Decrement
  decrement(product: { [x: string]: any }, qtn, loader) {
    this.disabledminus = true;
    //document.getElementById('').classList.re

    qtn.classList.add("d-none");
    loader.classList.remove("d-none");
    var newquantity = parseInt(product["quantity"]);
    newquantity--;
    //console.log"newquantity ", newquantity);
    if (newquantity > 0) {
      let body = {
        key: product["id"],
        quantity: newquantity,
      };
      //console.log"updateCartItem  quantity to server", body);
      this.Common.updateCartItem(body).subscribe((data) => {
        if (data.success) {
          //console.log"updateCartItem to server");
          this.disabledminus = false;
          //this.Common.changeCart("");
          this.Common.UpdatedSetting.next("decrement");
          this.getCartItemsFromServer();
          if (localStorage.getItem("front-lang") == "en") {
            this.toastrService.success("Product has been updated in cart.");
          } else {
            this.toastrService.success("تم تحديث المنتج فى السلة ");
          }
          //this.updateQuatnityNum(qtn , loader);
        } else {
          //console.log"some error occourd in server");
          this.disabledminus = false;
          qtn.classList.remove("d-none");
          loader.classList.add("d-none");
        }
      });
    } else {
      this.disabledminus = false;
      qtn.classList.remove("d-none");
      loader.classList.add("d-none");
    }
  }
  updateQuatnityNum(qtn, loader) {
    this.Common.getCart().subscribe((data) => {
      if (data.data) {
        this.loading = false;
        this.cart_products = data.data;
        this.products = [];
        if (this.cart_products.product_items.length > 0) {
          this.products = this.cart_products["product_items"];
          this.cart_products.panelOpenState = true;
        }


        this.cart_products["panelOpenState"] = true;
      } else {
        this.cart_products = {} as Product;
      }
      qtn.classList.remove("d-none");
      loader.classList.add("d-none");
    });
  }
  // public removeItem(product: any) {
  //   this.productService.removeCartItem(product);
  // }
  handleImageError(e) {
    e.src = "assets/images/product/placeholder2.jpg";
  }
  removeItem(product: any, cartId: any = "", cartitem: any) {
    this.loading = true;
    console.log(product, this.cart_products);
    let body = {
      key: product["id"],
      cart_id: cartId,
    };
    if (cartitem.product_items.length > 1) {
      this.Common.deleteCartItem(body).subscribe((data) => {
        console.log(data);

        if (data.data) {
          //console.log"deleteCartItem");
          //this.Common.changeCart("");
          if (localStorage.getItem("front-lang") == "en") {
            this.toastrService.error("Product deleted");
          } else {
            this.toastrService.error(" تم حذف المنتج ");
          }
          this.getCartItemsFromServer();
          this.Common.UpdatedSetting.next("deleted");
          this.Common.Updatedheaderone.next("remove");
        } else {
          console.log("some error occourd in server");
        }
        this.loading = false;
      });
    } else {
      this.emptycart();
    }
  }
  emptycart() {
    this.loading = true;
    this.Common.emptyCart(
      "",
      null,
      this.cart_products.id,
      "canceled"
    ).subscribe((data) => {
      console.log(data);

      if (data.success) {
        //this.getCartItemsFromServer();
        //this.Common.changeCart("");
        if (this.couponId) {
          this.deleteCoupon(this.cart_products.id, "delete-cart");
        }
        this.cart_products = {} as Product;
        this.Common.UpdatedSetting.next("empty");
        this.Common.Updatedheaderone.next("empty");
        this.products = []
        console.log(this.cart_products);
        if (localStorage.getItem("front-lang") == "en") {
          this.toastrService.error("cart has been removed.");
        } else {
          this.toastrService.error("تم حذف السلة");
        }
      } else {
        // somethng wrong
      }
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    });
  }
  getNoteDetails(id) {
    this.Common.getNoteDetails(id).subscribe((res) => {
      // console.log(res);
      if (res.success) {
        this.noteTitle = res.data.title;
        $("#note_description").html(res.data.description);
        // console.log(this.noteTitle);
      }
    });
  }
  isLogin() {
    console.log(this.products);
    if (localStorage.getItem("clientToken") == null) {
      this.goToLogin();
    } else {
      let inventoryStatus = false;
      // Get All Products In The Cart and Check Active
      for (const product of this.products) {
        console.log("invoice_edit_id ", product['invoice_edit_id']);
        if (product.active == 2 && product['invoice_edit_id'] == '0') {
          Swal.fire({
            html: `
              <span style="color: #dd0e1c;">
              ${product['product_name']}</span>
              جاري جرده لذلك قم بحذفه لاستكمال الطلب!
              `,
            icon: "warning",
            confirmButtonColor: getComputedStyle(
              document.querySelector("app-root")
            ).getPropertyValue("--theme-default"),
            cancelButtonColor: getComputedStyle(
              document.querySelector("app-root")
            ).getPropertyValue("--theme-default2"),
          });

          inventoryStatus = true;
          break;
        }
        else if(product.active == 0 && product['invoice_edit_id'] == '0')
        {
          Swal.fire({
            html: `
              <span style="color: #dd0e1c;">
              ${product['product_name']}</span>
              هذا المنتج معطل الآن
              `,
            icon: "warning",
            confirmButtonColor: getComputedStyle(
              document.querySelector("app-root")
            ).getPropertyValue("--theme-default"),
            cancelButtonColor: getComputedStyle(
              document.querySelector("app-root")
            ).getPropertyValue("--theme-default2"),
          });

          inventoryStatus = true;
          break;
        }
      }
      // let inventory_products: number = this.products.findIndex(ele => (ele.active == 2 || this.cart_products['invoice_edit_id'] == ''));
      if (inventoryStatus == true) {
        return;
      }
    }
    let userToken = localStorage.getItem("clientToken");
    if (userToken !== null && userToken !== "") {
      console.log("cart_products", this.cart_products);
      let allConditionsMet = true;

      for (let index = 0; index < this.cart_products['product_items']?.length; index++) {
        for (
          let j = 0;
          j < this.cart_products.product_items.length;
          j++
        ) {
          if (
            this.cart_products.product_items[j]["quantity"] >
            this.cart_products.product_items[j]["store_amounts_product"]
          ) {
            allConditionsMet = false;
            if (localStorage.getItem("front-lang") == "en") {
              Swal.fire({
                html: `
                  The selected quantity is not available for the product: 
                  <span style="color: #dd0e1c;">
                  ${this.cart_products.product_items[j]["product_name"]}</span>
                  Please update the product quantity or remove it from the cart to continue and complete your order
                  `,
                icon: "warning",
                confirmButtonColor: getComputedStyle(
                  document.querySelector("app-root")
                ).getPropertyValue("--theme-default"),
                cancelButtonColor: getComputedStyle(
                  document.querySelector("app-root")
                ).getPropertyValue("--theme-default2"),
              });
            } else {
              Swal.fire({
                html: `
                الكمية المحددة غير متوفرة للمنتج: 
                <span style="color: #dd0e1c;">
                ${this.cart_products.product_items[j]["product_name"]}</span>
                قم بتحديث كمية المنتج او احذفه من السلة للمتابعة و استكمال الطلب
                `,
                icon: "warning",
                confirmButtonColor: getComputedStyle(
                  document.querySelector("app-root")
                ).getPropertyValue("--theme-default"),
                cancelButtonColor: getComputedStyle(
                  document.querySelector("app-root")
                ).getPropertyValue("--theme-default2"),
              });
            }
            return;
          }
        }
      }
      if (allConditionsMet) {
        this.router.navigate(["/shop/checkout"]);
      }
    } else {
      // this.router.navigate(["/pages/login"]);
      this.goToLogin();
    }
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  loginclicked: boolean = false;

  // goToLogin() {
  //   this.loginclicked = true;
  // }
  // close() {
  //   this.loginclicked = false;
  // }
  step5Reached: boolean = false;
  stepSuccessReached: boolean = false;
  timeoutId: any;

  @ViewChild("login") loginSection;
  @ViewChild("loginFast") loginFast;

  goToLogin() {
    this.loginclicked = true;
  }

  close() {
    this.loginclicked = false;
    this.step5Reached = false;
    this.clearTimeout();
    if (this.stepSuccessReached) {
      window.location.reload();
    }
  }

  onStep5Reached() {
    this.step5Reached = true;
    this.startTimeout();
  }
  onstepSuccessReached() {
    this.stepSuccessReached = true;
  }

  startTimeout() {
    this.timeoutId = setTimeout(() => {
      this.close();
    }, 2000);
  }

  clearTimeout() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }
  }
  @HostListener("document:click", ["$event"])
  onDocumentClick(event: MouseEvent) {
    if (!this.loginFast || !this.loginSection) {
      return;
    }
    const clickedInside = this.loginFast.nativeElement.contains(event.target);
    const clickedButton = this.loginSection.nativeElement.contains(
      event.target
    );

    if (clickedInside && this.loginclicked) {
      if (this.step5Reached) {
        this.close();
      }
    } else if (!clickedInside && clickedButton && this.loginclicked) {
      this.close();
    }
  }

  calculateTextColor(): string {
    return this.Common.tinyColor("var(--theme-default)");
  }
}
