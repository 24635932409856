<!-- CATEGORIES SLIDER -->
<swiper [config]="categoriesSliderConfig" class="category-swiper">


    <ng-template class="single-slide-mobile" *ngFor="let item of itemsList; let i = index; let even = even; let odd = odd" swiperSlide>
        
        <a class="category-item" [routerLink]="['/shop/category/'+item[0]?.id+'/'+item[0]?.ser_name?.split(' ').join('-')]">
            <div class="category-image-block">
                <img [src]="servicesUrlimage+item[0]?.ser_image" *ngIf="item[0].ser_image" [alt]="'category_name'" class="" >
                <img src="assets/images/product/placeholder.jpg" *ngIf="!item[0].ser_image" alt="defaultImage"/>
            </div>
            
            <span class="category-title">{{item[0]?.ser_name}}</span>
        </a>
        <a class="category-item" *ngIf="item[1]?.id" [routerLink]="['/shop/category/'+item[1]?.id+'/'+item[1]?.ser_name?.split(' ').join('-')]">
            <div class="category-image-block">
                <img [src]="servicesUrlimage+item[1]?.ser_image" *ngIf="item[1].ser_image" [alt]="'category_name'" class="">
                <img src="assets/images/product/placeholder.jpg"  *ngIf="!item[1].ser_image" alt="defaultImage" >
            </div>
            <span class="category-title">{{item[1]?.ser_name}}</span>
        </a>
    </ng-template>

    <!-- Categories Home -->
    <ng-template class="single-slide-desktop"
        *ngFor="let item of itemsListDesktop; let i = index; let even = even; let odd = odd" swiperSlide>
        <a class="category-item" [routerLink]="['/shop/category/'+item[0].id+'/'+item[0].ser_name?.split(' ').join('-')]">
            <div class="category-image-block"  *ngIf="item[0].ser_image">
                <img [src]="servicesUrlimage+item[0]?.ser_image" [alt]="'category_name'" class="">
            </div>
            <div class="category-image-block" *ngIf="!item[0].ser_image">
                <img src="assets/images/product/placeholder.jpg" alt="defaultImage"> 
            </div>
            <span class="category-title">{{item[0]?.ser_name}}</span>
        </a>
    </ng-template>
</swiper>


