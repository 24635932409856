import { Pipe, PipeTransform } from '@angular/core';
import * as moment from "moment";

@Pipe({
  name: "fromNowMoment",
})
export class FromNowMomentPipe implements PipeTransform {
  public _lang_code =
    localStorage.getItem("front-lang") != null
      ? localStorage.getItem("front-lang")
      : "ar";

  transform(date: string): string {
    return moment(date).locale(this._lang_code).fromNow();
  }
}
