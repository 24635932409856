import { HttpBackend, HttpClient } from "@angular/common/http";
import { Inject, Injectable, Optional, PLATFORM_ID } from "@angular/core";
import * as bcrypt from "bcryptjs";
import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { Meta } from "@angular/platform-browser";
import { isPlatformBrowser, isPlatformServer } from "@angular/common";
import { REQUEST } from "@nguniversal/express-engine/tokens";
import { Request } from "express";
import { UserService } from "./user.service";
import { DataContact } from "../classes/data-contact";

@Injectable({
  providedIn: "root",
})
export class MasterApiService {
  public masterData: any;
  private hashedValPromise: Promise<any> | null = null;

  httpClient: HttpClient;

  // ApiUrl = 'http://192.168.1.220/jameiat-aljahra/api/web-api/';
  // apiSecret = 'http://192.168.1.220/jameiat-aljahra/api/secret-api/';
  // _uploadFolder = 'http://192.168.1.220/jameiat-aljahra/api/uploads/';

  ApiUrl = 'https://aljahracoop.com/api/web-api/';
  apiSecret = 'https://aljahracoop.com/api/secret-api/';
  _uploadFolder = 'https://aljahracoop.com/api/uploads/';

  AppVersion = "web-1.0.23";

  constructor(
    private handler: HttpBackend,
    private http: HttpClient,
    @Optional() @Inject(REQUEST) private request: Request,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.httpClient = new HttpClient(handler);
  }

  initialize(): any {
    return this.getAllSettings(this.ApiUrl);
  }

  // getHashedVal(): Promise<any> {
  //   let hostname: string;

  //   if (isPlatformBrowser(this.platformId)) {
  //     hostname = window.location.hostname;
  //     console.log("client side");
  //   } else if (isPlatformServer(this.platformId)) {
  //     hostname = this.request.hostname;
  //     console.log("server side");
  //   }
  //   console.log("host_name", hostname);
  //   if (!this.hashedValPromise) {
  //     console.log("Fetching hashed value...");
  //     let pass = bcrypt.hashSync("it-Plus-Secrit-Key-API-Malyaan");
  //     const body = {
  //       parse_host: hostname,
  //     };
  //     const headers = { "Secret-Api": pass };
  //     const options = { headers: headers };
  //     this.hashedValPromise = this.httpClient
  //       .post<any>(this.masterApiUrl + "gethashedVal", body, options)
  //       .toPromise()
  //       .then((res) => {
  //         console.log("fetching hash val:", res);
  //         this.masterData = res;
  //         return res;
  //       })
  //       .catch((err) => {
  //         console.log("master api error ", err);
  //         return Promise.reject(err);
  //       });
  //   }
  //   return this.hashedValPromise;
  // }

  public dataContactInfo = new BehaviorSubject<DataContact>({} as DataContact);

  // getAllContact(): Promise<void> {
  //   console.log("Calling getAllContact");
  //   return this.getAllContanctInfo(this.ApiUrl)
  //     .toPromise()
  //     .then((data) => {
  //       if (data) {
  //         console.log("fetching contact info", data);

  //         this.updateDataContact(data.data);
  //         localStorage.setItem("contactinfo", JSON.stringify(data.data));
  //       } else {
  //         console.error("No data returned from getAllContanctInfo");
  //       }
  //     })
  //     .catch((err) => {
  //       console.error("Error in getAllContact", err);
  //       return Promise.reject(err);
  //     });
  // }

  getAllSettings(settingsUrl) {
    const url = settingsUrl +'settings.php?action='+ "getAllSettings";
    console.log("contact info", url);
    this.http.get<any>(url).subscribe({
      next: (data) => {
        console.log(data);
        this.updateDataContact(data.data);
        return data
      },
      error: (err) => {
        console.log("Error fetching contact info", err);
        return err
      }
    })
    
  }

  updateDataContact(newData: DataContact) {
    this.dataContactInfo.next(newData);
  }

  getCurrentDataContact(): Observable<DataContact> {
    return this.dataContactInfo.asObservable();
  }
}
