import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-footer-modal',
  templateUrl: './footer-modal.component.html',
  styleUrls: ['./footer-modal.component.scss']
})
export class FooterModalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();

  onCloseModal() {
    this.closeModal.emit();
  }
}
