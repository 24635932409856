import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from 'src/app/shared/services/common.service';
import { ProductService } from 'src/app/shared/services/product.service';

@Component({
  selector: 'app-product-item-checkout',
  templateUrl: './product-item-checkout.component.html',
  styleUrls: ['./product-item-checkout.component.scss']
})
export class ProductItemCheckoutComponent implements OnInit {
  @Input() products: any;
  urlimage = this.commonService._ImageUrl + "products/";

  constructor(
    private commonService: CommonService,
    public productService: ProductService
  ) { }

  ngOnInit(): void {
    console.log(this.products);
    
  }
  handleImageError(e){
    e.src = 'assets/images/product/placeholder2.jpg';
  }
  removeBrackets(str: string): string {
    return str.replace(/[/(%\)]/g, '');
  }
  getDiscountPercentage(last_price: string, price: string) {
    var l_p = parseInt(last_price);
    var p = parseInt(price);
    var res = ((l_p - p) / l_p) * 100;
    
    return Math.round(res);
  }
  getSubtraction(last_price: string, price: string, qunt = 1) {
    var l_p = parseInt(last_price);
    var p = parseInt(price);
    var res = (l_p - p) * qunt;

    return Math.round(res);
  }
}
