<li *ngFor="let childrenItem of menuItem.children">
  <a [routerLink]="childrenItem.path" routerLinkActive="active"  
      *ngIf="childrenItem.type === 'link'" class="has-submenu">
    {{ childrenItem.title | translate }}
    
    <span class="sub-arrow" *ngIf="childrenItem.children" (click)="toggletNavActive(childrenItem, $event)"></span>
    </a>

    <!-- 3rd Level Menu -->
    <ul *ngIf="childrenItem.children" [class.opensubchild]="childrenItem.active" class="nav-sub-childmenu">
        <app-nav-menu-item [menuItem]="childrenItem"></app-nav-menu-item>
    </ul>
</li>