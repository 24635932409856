import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-to-cart-block',
  templateUrl: './add-to-cart-block.component.html',
  styleUrls: ['./add-to-cart-block.component.scss']
})
export class AddToCartBlockComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
