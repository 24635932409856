import {
  Component,
  OnInit,
  Injectable,
  PLATFORM_ID,
  Inject,
  OnDestroy,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  Input,
} from "@angular/core";
import { isPlatformBrowser } from "@angular/common";
import { Observable, Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { ProductService } from "../../services/product.service";
import { Product } from "../../classes/product";
import { CommonService } from "../../services/common.service";
import { UserService } from "../../../shared/services/user.service";
import { HostListener } from "@angular/core";

import {
  Router,
  NavigationEnd,
  Event,
  NavigationStart,
  NavigationError,
  RoutesRecognized,
} from "@angular/router";
import { ToastrService } from "ngx-toastr";
import Swal from "sweetalert2";

@Component({
  selector: "app-settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.scss"],
})
export class SettingsComponent implements OnInit, OnDestroy {
  total_price;
  total_discount;
  total_quantity_discount;
  total_coupon_discount;
  public products: Product[] = [];
  public cart_products: any = [];
  public search: boolean = false;
  public query: string = "";
  userLogedIn: boolean = false;
  currentUser: any;
  user_img = "";
  existImg: boolean = false;
  user_full_name = "";
  usre_id: any;
  cartId: any;
  screenSize = false;
  screenHeight: number;
  screenWidth: number;
  cartPrice;
  errorImage: string;
  errorOccurredMessage: string = "";
  urlimage;
  loginclicked: boolean = false;
  allSupportAndBlog: any = [];
  imgPath: string = "";
  ShowSupportBlock: number = 0;

  public languages = [
    {
      name: "English",
      code: "en",
    },
    {
      name: "عربي",
      code: "ar",
    },
  ];

  public currencies = [
    {
      name: "Euro",
      currency: "EUR",
      price: 0.9, // price of euro
    },
    {
      name: "Rupees",
      currency: "INR",
      price: 70.93, // price of inr
    },
    {
      name: "Pound",
      currency: "GBP",
      price: 0.78, // price of euro
    },
    {
      name: "Dollar",
      currency: "USD",
      price: 1, // price of usd
    },
  ];
  sub: Subscription;

  @HostListener("window:resize", ["$event"])
  onResize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    // console.log(this.screenHeight, this.screenWidth);
  }
  origianlProducts: any = [];
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private translate: TranslateService,
    public user: UserService,
    public productService: ProductService,
    public Common: CommonService,
    private router: Router,
    private ToastrService: ToastrService
  ) {
    this.onResize();
    // this.productService.cartItems.subscribe(
    //   (response) => {

    //     this.products = response
    //   }
    // );
    // this.Common.changeCartUpdated.subscribe((name) =>
    // {
    //   console.log(name);

    //   this.getCartItemsFromServer();
    //   console.log('constructor');
    // }

    // );

    this.urlimage = this.Common._ImageUrl + "products/";
    this.imgPath = Common._ImageUrl + "support-and-blog/";
    this.sub = this.Common.UpdatedSetting.subscribe({
      next: (res) => {
        //console.log(res);
        this.getCartItemsFromServer();
      },
    });
  }
  handleImageError(e) {
    e.src = "assets/images/product/placeholder2.jpg";
  }
  ngOnInit(): void {
    this.user.getLoggedInName.subscribe((name) => this.changeName(name));
    //this.getCartItemsFromServer();
    this.checkOuth();
    this.getAllSupportAndBlogCategory();
  }
  private changeName(name: string): void {
    this.user_full_name = name;
    this.checkOuth();
  }
  checkOuth() {
    if (this.user.getToken() !== null) {
      this.userLogedIn = true;
      this.currentUser = JSON.parse(this.user.getCurrentUser())!;
      this.usre_id = this.currentUser?.id;
      this.user_img = this.currentUser?.img;
      this.existImg =
        this.user_img != "" && this.user_img != null ? true : false;
      this.user_full_name = this.currentUser?.user_name;
      // console.log("curent user",this.currentUser);
    }
  }
  removeBrackets(str: string): string {
    return str?.replace(/[/(\%)]/g, "");
  }
  // The Error Here
  getCartItemsFromServer() {
    this.Common.getCart().subscribe((data) => {
      if (data.data) {
        // Added Line Code

        this.cart_products = data.data;
        this.products = [];
        this.origianlProducts = [];
        this.cart_products["product_items"].forEach((ele) => {
          this.origianlProducts.push(ele);
          this.products.push(ele);
          ele.panelOpenState = true;
        });
        // console.log(this.products);
        this.cartId = this.cart_products["id"];
        this.total_price = this.cart_products["total_price"];
        this.total_quantity_discount =
          this.cart_products["total_quantity_discount"];

        this.total_discount = this.cart_products["total_discount"];

        this.total_coupon_discount =
          this.cart_products["total_coupon_discount"];
        // console.log(this.products);
      } else {
        //console.log"no data in  getCartItemsFromServer SettingsComponent");
        this.cart_products = [];
        this.products = [];
        this.cartId = "";
      }
    });
  }

  searchToggle() {
    this.search = !this.search;
  }

  goTo(to) {
    this.router.navigate([to]);
  }

  // Layout Type
  customizeLayoutType(val) {
    if (val == "rtl") {
      document.body.classList.remove("ltr");
      document.body.classList.add("rtl");
    } else {
      document.body.classList.remove("rtl");
      document.body.classList.add("ltr");
    }
  }

  changeLanguage(code) {
    //console.log"language code is ", code);
    if (code == "ar") {
      document.body.classList.remove("ltr");
      document.body.classList.add("rtl");
      localStorage.setItem("front-lang", "ar");
    } else {
      document.body.classList.remove("rtl");
      document.body.classList.add("ltr");
      localStorage.setItem("front-lang", "en");
    }

    if (isPlatformBrowser(this.platformId)) {
      this.translate.use(code);
    }
    location.reload();
  }

  goToSearch() {
    if (this.query != "") {
      this.router.navigate(["/pages/search/" + this.query]);
    }
  }

  get getTotal(): Observable<number> {
    return this.productService.cartTotalAmount();
  }

  removeItem(product: any) {
    let cartitem = this.products.filter((ele) => ele.id == product.cart_id);
    console.log(product);
    
    let body = {
      key: product["id"],
      cart_id: product.cart_id,
    };
    let index = cartitem.indexOf(cartitem[0]);
    console.log(this.router.url.split("/")[2]);
    if (cartitem.length == 1) {
      this.emptycart();
      return;
    }
    this.Common.deleteCartItem(body).subscribe((data) => {
      this.Common.UpdatedCartProducts.next(product['product_id'])
      if (data.data) {
        if (this.router.url.split("/")[2] == "checkout") {
          this.Common.UpdatedChecckOut.next("remove");
        }
        if (this.router.url.split("/")[2] == "cart") {
          console.log("setting/cart");
          this.Common.UpdatedCart.next("remove");
        }
        //console.log"deleteCartItem");
        //this.Common.changeCart("");
        if (localStorage.getItem("front-lang") == "en") {
          this.ToastrService.error("Product deleted");
        } else {
          this.ToastrService.error(" تم حذف المنتج ");
        }
        this.getCartItemsFromServer();
        //this.getCartItemsFromServer();
        //this.productService.updateData();
      } else {
        console.log("some error occourd in server");
      }
    });
  }
  emptycart() {
    this.Common.emptyCart(
      "",
      null,
      this.cart_products.id,
      "canceled"
    ).subscribe((data) => {
      console.log(data);

      if (data.success) {
        //this.getCartItemsFromServer();
        //this.Common.changeCart("");
        this.products = [];
        console.log(this.cart_products);
        if (localStorage.getItem("front-lang") == "en") {
          this.ToastrService.error("cart has been removed.");
        } else {
          this.ToastrService.error("تم حذف المتجر");
        }
        if (this.router.url.split("/")[2] == "cart") {
          this.Common.UpdatedCart.next("empty");
        }
        if (this.router.url.split("/")[2] == "checkout") {
          this.Common.UpdatedChecckOut.next("empty");
        }

        this.getCartItemsFromServer();
      } else {
        // somethng wrong
      }
    });
  }
  changeCurrency(currency: any) {
    this.productService.Currency = currency;
  }

  isLogin() {
    // let inventoryStatus = false;
    //   // Get All Products In The Cart and Check Active
    // for (const product of this.products) {
    //   console.log("invoice_edit_id ", product['invoice_edit_id'] , product.active);
    //   if (product.active == 2 && product['invoice_edit_id'] == '0') {
    //     Swal.fire({
    //       html: `
    //         <span style="color: #dd0e1c;">
    //         ${product['product_name']}</span>
    //         جاري جرده لذلك قم بحذفه لاستكمال الطلب!
    //         `,
    //       icon: "warning",
    //       confirmButtonColor: getComputedStyle(
    //         document.querySelector("app-root")
    //       ).getPropertyValue("--theme-default"),
    //       cancelButtonColor: getComputedStyle(
    //         document.querySelector("app-root")
    //       ).getPropertyValue("--theme-default2"),
    //     });
    //     inventoryStatus = true;
    //     break;
    //   }
    //   else if(product.active == 0 && product['invoice_edit_id'] == '0')
    //     {
    //       Swal.fire({
    //         html: `
    //           <span style="color: #dd0e1c;">
    //           ${product['product_name']}</span>
    //           هذا المنتج معطل الآن
    //           `,
    //         icon: "warning",
    //         confirmButtonColor: getComputedStyle(
    //           document.querySelector("app-root")
    //         ).getPropertyValue("--theme-default"),
    //         cancelButtonColor: getComputedStyle(
    //           document.querySelector("app-root")
    //         ).getPropertyValue("--theme-default2"),
    //       });
  
    //       inventoryStatus = true;
    //       break;
    //     }
      
    // }
    //   // let inventory_products: number = this.products.findIndex(ele => (ele.active == 2 || this.cart_products['invoice_edit_id'] == ''));
    // if (inventoryStatus == true) {
    //   return;
    // }
    let userToken = localStorage.getItem("clientToken");
    if (userToken !== null && userToken !== "") {
      this.router.navigate(["/shop/checkout"]);
    } else {
      // this.router.navigate(["/pages/login"]);
      this.goToLogin();
    }
  }
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
  // goToLogin() {
  //   this.loginclicked = true;
  // }

  // close() {
  //   this.loginclicked = false;
  // }

  step5Reached: boolean = false;
  stepSuccessReached: boolean = false;
  timeoutId: any;

  @ViewChild("login") loginSection;
  @ViewChild("loginFast") loginFast;

  goToLogin() {
    this.loginclicked = true;
  }

  close() {
    this.loginclicked = false;
    this.step5Reached = false;
    this.clearTimeout();
    if (this.stepSuccessReached) {
      window.location.reload();
    }
  }

  onStep5Reached() {
    this.step5Reached = true;
    this.startTimeout();
  }
  onstepSuccessReached() {
    this.stepSuccessReached = true;
  }

  startTimeout() {
    this.timeoutId = setTimeout(() => {
      this.close();
    }, 500);
  }

  clearTimeout() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }
  }
  @HostListener("document:click", ["$event"])
  onDocumentClick(event: MouseEvent) {
    if (!this.loginFast || !this.loginSection) {
      return;
    }
    const clickedInside = this.loginFast.nativeElement.contains(event.target);
    const clickedButton = this.loginSection.nativeElement.contains(
      event.target
    );

    if (clickedInside && this.loginclicked) {
      if (this.step5Reached) {
        this.close();
      }
    } else if (!clickedInside && clickedButton && this.loginclicked) {
      this.close();
    }
  }
  userLogOut() {
    localStorage.removeItem("currentUserFront");
    localStorage.removeItem("clientToken");
    localStorage.removeItem("promo");
    this.userLogedIn = false;
    this.currentUser = [];
    this.usre_id = "";
    this.user_img = "";
    this.user.logout();
    this.Common.UpdatedSetting.next("logout");
    this.router.navigate(["/"]).then(() => {
      window.location.reload();
    });
  }
  getAllSupportAndBlogCategory() {
    this.Common.getAllSupportAndBlogCategory().subscribe((data) => {
      if (data.success) {
        this.allSupportAndBlog = data.data;
        this.allSupportAndBlog?.forEach((element) => {
          if (element.show_in_header == "1") {
            this.ShowSupportBlock += 1;
          }
        });
      }
    });
  }
}
