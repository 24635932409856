import { TokenService } from './app/shared/services/token.service';
import { Injectable, Injector } from "@angular/core";
import { HttpInterceptor } from "@angular/common/http";
import { UserService } from "./app/shared/services/user.service";
import { MasterApiService } from "./app/shared/services/master-api.service";
@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
  constructor(
    private injector: Injector,
    public masterApiService: MasterApiService,
  ) { }

  intercept(req: any, next: any) {
    var authService = this.injector.get(UserService);

    if (authService.getUserToken()) {
      var tokenizedReq = req.clone({
        setHeaders: {
          "Front-Lang": localStorage.getItem("front-lang") ? localStorage.getItem("front-lang") : 'ar',
          "User-Token": authService.getUserToken(),
          // "affiliator-token": this.masterApiService.masterData.shop_token,
          // "dealer-token": this.masterApiService.masterData.dealer_token + '-' + Date.now(),
          // "Device-Id": authService.getDeviceId() 
          "Device-Id": localStorage.getItem("Device-Id") ? localStorage.getItem("Device-Id") : "",
          'App-Version': this.masterApiService.AppVersion
        },
      });
    } else {
      var tokenizedReq = req.clone({
        setHeaders: {
          "Front-Lang": localStorage.getItem("front-lang") ? localStorage.getItem("front-lang") : "ar",
          // "affiliator-token": environment.affiliator_token,
          // "dealer-token": this.masterApiService.masterData.dealer_token + '-' + Date.now(),
          // "affiliator-token": this.masterApiService.masterData.shop_token,
          // "Device-Id": authService.getDeviceId()
          "Device-Id": localStorage.getItem("Device-Id") ? localStorage.getItem("Device-Id") : "",
          'App-Version': this.masterApiService.AppVersion
        },
      });
    }

    return next.handle(tokenizedReq);
  }
  

}
