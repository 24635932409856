<app-breadcrumb [title]="page_name" [breadcrumb]="page_name"></app-breadcrumb>
<!-- section start -->
<section class="cart-section section-b-space">
    <div class="container">

        <div class="loader-block" *ngIf="loading">
            <img src="assets/images/loader.gif">
        </div>
        <!-- <div class="layer-modal" #layer (click)="onCloseModalDetails(modal , layer)"></div> -->
        <!-- <div class="modal-container">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="modal" #modal>
                            <div class="modal-content">
                                <div class="header-modal d-flex align-items-center justify-content-between">
                                    <h4 style="color: black;">{{noteTitle}}</h4>
                                    <button mat-button (click)="onCloseModalDetails(modal , layer)" style="background: transparent;
                                    border: none;">
                                        <mat-icon>close</mat-icon>
                                    </button>
                                </div>
                            </div>
                            <div class="py-5 d-flex justify-content-center align-items-center">
                                <div id="note_description">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- ? Collapse -->
        <div  class="mb-3">
            <mat-accordion *ngIf="products?.length > 0 && !loading">
                <mat-expansion-panel [hideToggle]="true" class="pt-3 pb-3 bg-light"
                    [(expanded)]="cart_products.panelOpenState" (opened)="cart_products.panelOpenState = true"
                    (closed)="cart_products.panelOpenState = false" style="border: 1px solid #CCC;">
                    <mat-expansion-panel-header>
                        <mat-panel-title class="m-0 align-items-center">
                            <div class="px-2 d-flex justify-content-between align-items-center w-100">
                                <div class="d-flex">
                                    <img 
                                        [src]="Common._ImageUrl +  'front_images/' + contactInfo.front_fav_icon"
                                        width="35" style="border-radius: 50%; border: 1px solid #DDD;" height="35"
                                        [border]="1" alt="" srcset="">
                                    <p class="px-2 my-auto text-black font-weight-bold price-header">
                                        {{'order shipping'|translate}}
                                        {{
                                        contactInfo.tag_title
                                        }}
                                    </p>

                                </div>
                                <span class="font-weight-bold price-header">{{ (cart_products['total_price'] -
                                    cart_products['total_discount'] -
                                    cart_products['total_coupon_discount']-cart_products['total_coupon_discount']-cart_products['total_quantity_discount']) | number:'1.3-3'
                                    }}
                                    {{productService?.Currency.name}}
                                </span>

                            
                            </div>
                        </mat-panel-title>
                        <mat-panel-description>
                            <div class="px-2 mt-3 d-flex align-items-center justify-content-between w-100">
                                <button class="btn text-light del-products" (click)="emptycart()">
                                    <span *ngIf="cart_products['invoice_edit_id'] && cart_products['invoice_edit_id'] == 0">
                                        {{'Delete all products' | translate}}
                                    </span>
                                    <span *ngIf="cart_products['invoice_edit_id'] && cart_products['invoice_edit_id'] != 0">
                                        {{'cancel edit' | translate}}
                                    </span>
                                </button>
                                <span *ngIf="cart_products.panelOpenState == true" class="d-flex bg-dark text-light see-btn"
                                    [class]="Common.isRtl ?'see-padding-ar':'see-padding-en' ">
                                    <span class="material-symbols-outlined">
                                        <mat-icon>keyboard_arrow_down</mat-icon>
                                    </span>
                                    <span>
                                        {{'see less' | translate}}
                                    </span>
                                </span>
                                <span *ngIf="cart_products.panelOpenState == false" class="d-flex bg-dark text-light see-btn"
                                    [class]="Common.isRtl ?'see-padding-ar':'see-padding-en' ">
                                    <span class="material-symbols-outlined">
                                        <mat-icon>keyboard_arrow_up</mat-icon>
                                    </span>
                                    <span>
                                        {{'see more' | translate}}
                                    </span>
                                </span>
                            </div>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div class="mt-5">
                        <div class="edit-order-block d-flex justify-content-center align-items-center flex-column"
                            *ngIf="cart_products['invoice_edit_id'] && cart_products['invoice_edit_id'] != 0 && !loading">
                            <span class="text-center">
                                {{'Order' | translate}} {{'Number2' | translate}} {{cart_products["count_invoice_type"]}}
                                {{'is under modification' | translate}} {{'Now' | translate}}
                            </span>
                            <span class="text-center" style="color: red;">
                                {{ 'Do' | translate}}{{'confirm' | translate}} {{'Order' | translate}} {{'after completing the modification to update' | translate}}
                            </span>
                        </div>


                        <div class="row" *ngIf="cart_products.product_items?.length > 0 && !loading">

                            <div class="col-sm-8">

                                <div class="mobile-cart-layout">
                                    <div class="single-product-layout "
                                        *ngFor="let product of cart_products.product_items; let i = index">
                                        <div class="single-product">
                                            <a class="cart-link" *ngIf="product?.images?.length > 0"
                                                [routerLink]="['/shop/product/', product?.product_id ,product.meta_url ? product.meta_url?.split(' ').join('-') : removeBrackets(product.product_name ? product.product_name:'').split(' ').join('-')]">
                                                <img *ngIf="product.image && product.images[0]  ['type'] === 'image'"
                                                    [src]="urlimage+product.original_image"
                                                    [alt]="product?.product_name"
                                                    (error)="handleImageError($event.target)" class="img-fluid">

                                                <img *ngIf="product.original_image && product.images[0]['type'] === 'video'"
                                                    src="assets/images/video.png">

                                                <img *ngIf="!product.original_image"
                                                    src="assets/images/product/placeholder.jpg"
                                                    [alt]="product?.product_name">
                                            </a>
                                            <a class="cart-link"
                                                [routerLink]="['/shop/product/', product?.product_id , product.meta_url  !== null ? product.meta_url?.split(' ').join('-') : removeBrackets(product.product_name ? product.product_name:'').split(' ').join('-')]"
                                                *ngIf="product?.images?.length == 0">
                                                <img src="assets/images/product/placeholder.jpg" class="product-image">
                                            </a>

                                            <div class=" title-quantity-price-block">
                                                <div class="title">
                                                    <a
                                                        [routerLink]="['/shop/product/', product?.product_id ,product.meta_url ? product.meta_url.split(' ').join('-') : removeBrackets(product.product_name ? product.product_name:'').split(' ').join('-')]">
                                                        {{ product?.product_name ? product?.product_name :"" | titlecase
                                                        }}
                                                    </a>
                                                </div>
                                                <div class="gap-2 d-flex flex-column">
                                                    <div class="d-flex justify-content-between">
                                                        <div class="mb-1 d-flex justify-content-start flex-column">
                                                            <span class="rounded offer-data"
                                                                *ngIf="(product?.last_price > 0) && (+product?.last_price > +product?.price &&product.quantity_last_price ==null)"><b>{{'Reduced
                                                                    discount'
                                                                    |
                                                                    translate}} {{
                                                                    getDiscountPercentage(product?.last_price,product?.price)+
                                                                    "%"
                                                                    }}</b>
                                                            </span>

                                                            <span class="mb-2 offer-data"
                                                                *ngIf="(product?.last_price > 0) && (+product?.last_price > +product?.price &&+product.quantity_last_price > 0)"><b>{{'Reduced
                                                                    discount'
                                                                    |
                                                                    translate}} {{
                                                                    getDiscountPercentage(product?.last_price,product?.quantity_last_price)+
                                                                    "%"
                                                                    }}</b>
                                                            </span>

                                                            <span class="bg-black offer-data"
                                                                *ngIf="product?.price > 0 && product.quantity_last_price !=null  "><b>{{'quantitydiscount'
                                                                    |
                                                                    translate}}
                                                                </b>
                                                            </span>
                                                        </div>
                                                        <div class="mb-1 d-flex justify-content-start flex-column">
                                                            <span
                                                                *ngIf="(product?.last_price > 0) && (+product?.last_price > +product?.price &&product.quantity_last_price ==null)"
                                                                class="mb-2 discount-price text-disc">
                                                                -{{
                                                                getSubtraction(product?.last_price,product?.price,product?.quantity) | number:'1.3-3'}}
                                                                {{productService?.Currency.name}}
                                                            </span>
                                                            <span
                                                                *ngIf="(product?.last_price > 0) && (+product?.last_price > +product?.price &&+product.quantity_last_price > 0)"
                                                                class="mb-2 discount-price text-disc">
                                                                -{{
                                                                getSubtraction(product?.last_price,product?.quantity_last_price,product?.quantity) | number:'1.3-3'}}
                                                                {{productService?.Currency.name}}
                                                            </span>

                                                            <span
                                                                *ngIf="product?.price > 0 && +product.quantity_last_price > 0"
                                                                class="discount-price text-disc">
                                                                - {{
                                                                getSubtraction(product?.quantity_last_price,product?.price,product?.quantity)}}
                                                                {{productService?.Currency.name}}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="quantity-price-block ">
                                                    <div class="price-block">
                                                        <span class="price">
                                                            {{ ((product?.price | discount:product) *
                                                            productService?.Currency.price) | number:'1.3-3'
                                                            }}
                                                            {{productService?.Currency.name}}
                                                        </span>
                                                        <del *ngIf="product?.price > 0 && +product?.quantity_last_price >0"
                                                            class="old-price">{{
                                                            product?.quantity_last_price | number:'1.3-3' }}
                                                            {{productService?.Currency.name}}</del>
                                                        <del *ngIf="product?.price > 0 && (product?.last_price > 0)"
                                                            class="old-price">{{
                                                            product?.last_price | number:'1.3-3' }}
                                                            {{productService?.Currency.name}}</del>

                                                    </div>

                                                    <div class="quantity-block align-items-start"
                                                        *ngIf="product?.active == 2">
                                                        <span class="text-danger">{{ "Inventory is in progress" |
                                                            translate
                                                            }}</span>

                                                    </div>
                                                    <div class="quantity-block align-items-start"
                                                        *ngIf="product?.active != 2">
                                                        <div class="increase-decrease-button"
                                                            (click)="decrement(product , qtn , loader)">
                                                            <i class="fa fa-minus" aria-hidden="true"></i>
                                                        </div>
                                                        <div class="quantity-number-block">
                                                            <span class="quantity-number"
                                                                #qtn>{{product.quantity}}</span>
                                                            <div class="mx-auto loader-block d-none w-100" #loader>
                                                                <img class="loader-gif" src="assets/images/loader.gif">
                                                            </div>
                                                        </div>
                                                        <div class="increase-decrease-button"
                                                            (click)="increment(product , qtn , loader)">
                                                            <i class="fa fa-plus" aria-hidden="true"></i>
                                                        </div>
                                                    </div>

                                                    <div class="text-right total-price-block">
                                                        <span class="total-price">
                                                            {{ (((product?.price | discount:product) * product?.quantity)
                                                            *
                                                            productService?.Currency.price) | number:'1.3-3' }}
                                                            {{productService?.Currency.name}}
                                                        </span>

                                                    </div>
                                                    <div [class]="this.Common.isRtl ? 'mr-auto' : 'ml-auto'">
                                                        <div *ngIf="(cart_products['invoice_edit_id'] == '0') || ( product?.active != 2 && product['invoice_edit_id'] != '0' ) "
                                                            class=" options-block"
                                                            (click)="removeItem(product ,  cart_products['id'] , cart_products)">
                                                            <i class="fa fa-trash" aria-hidden="true"></i>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <table class="table cart-table table-responsive-xs">

                                    <tbody>
                                        <tr *ngFor="let product of products; let i = index">
                                            <td class="image-cell">
                                                <a [routerLink]="['/shop/product/', product?.product_id ,product.meta_url ? product.meta_url?.split(' ').join('-') : removeBrackets(product.product_name ? product.product_name:'').split(' ').join('-')]"
                                                    *ngIf="product?.images.length > 0">
                                                    <img *ngIf="product.image && product.images[0]  ['type'] === 'image'"
                                                        [src]="urlimage+product.original_image"
                                                        [alt]="product?.product_name"
                                                        (error)="handleImageError($event.target)">

                                                    <img *ngIf="product.image && product.images[0]['type'] === 'video'"
                                                        src="assets/images/video.png">

                                                    <img *ngIf="!product.original_image"
                                                        src="assets/images/product/placeholder.jpg"
                                                        [alt]="product?.product_name">
                                                </a>
                                                <a [routerLink]="['/shop/product/', product?.product_id , product.meta_url   ? product.meta_url?.split(' ').join('-') : removeBrackets(product.product_name ? product.product_name:'').split(' ').join('-') ]"
                                                    *ngIf="product?.images?.length == 0">
                                                </a>
                                                <a [routerLink]="['/shop/product/', product?.product_id , product.meta_url   ? product.meta_url?.split(' ').join('-') : removeBrackets(product.product_name ? product.product_name:'').split(' ').join('-')]"
                                                    *ngIf="product?.images.length == 0">

                                                    <img src="assets/images/product/placeholder.jpg">

                                                </a>
                                            </td>
                                            <td>
                                                <div class="offer-data-container">
                                                    <a
                                                        [routerLink]="['/shop/product/', product?.product_id ,product.meta_url   ? product.meta_url?.split(' ').join('-') : removeBrackets(product.product_name ? product.product_name:'').split(' ').join('-')]">{{
                                                        product?.product_name ? product?.product_name :""
                                                        |
                                                        titlecase }}</a>
                                                </div>

                                                <div class="mobile-cart-content row">
                                                    <div class="col-xs-3">
                                                        <div class="qty-box">
                                                            <div class="input-group">
                                                                <input type="text" name="quantity"
                                                                    class="form-control input-number" disabled
                                                                    [(ngModel)]="product.quantity">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-xs-3 price">
                                                        <h2 class="td-color">
                                                            {{ ((product?.price | discount:product) *
                                                            productService?.Currency.price) | number:'1.3-3'
                                                            }}
                                                            {{productService?.Currency.name}}

                                                        </h2>
                                                    </div>
                                                    <div class="col-xs-3">
                                                        <h2 class="td-color">
                                                            <a *ngIf="(product['invoice_edit_id'] == '0') || ( product.active != 2 && product['invoice_edit_id'] != '0' )"
                                                                (click)="removeItem(product , cart_products['id'] , cart_products)"
                                                                class="icon">
                                                                <i class="fa fa-trash" aria-hidden="true"></i>
                                                            </a>
                                                        </h2>
                                                    </div>
                                                </div>
                                            </td>
                                            <td style="width: 150px;">
                                                <div class="offer-data-container2">
                                                    <h2 class="td-color text-start">
                                                        {{ ((product.price | discount:product) *
                                                        productService?.Currency.price) | number:'1.3-3'
                                                        }}
                                                        {{productService?.Currency.name}}
                                                        <del *ngIf="product?.price > 0 && (product?.last_price > 0)"
                                                            class="pt-1 d-block">{{
                                                            product?.last_price | number:'1.3-3'}}
                                                            {{productService?.Currency.name}}</del>

                                                    </h2>

                                                </div>

                                                <div class="offer-data-container1"
                                                    *ngIf="(product?.offer_id > 0) &&(product?.last_price == null || product?.last_price == ''  ) ">
                                                    <span class="offer-data">
                                                        <b>
                                                            {{product?.offer_data['title']}}
                                                        </b>
                                                    </span>
                                                </div>
                                                <div class="offer-data-container1"
                                                    *ngIf="(product?.last_price > 0) && (+product?.last_price > +product?.price &&product.quantity_last_price ==null)">
                                                    <span class=" offer-data"><b>{{'Reduced
                                                            discount'
                                                            |
                                                            translate}} {{
                                                            getDiscountPercentage(product?.last_price,product?.price)+
                                                            "%"
                                                            }}</b>
                                                    </span>
                                                </div>
                                                <div class="offer-data-container1"
                                                    *ngIf="(product?.last_price > 0) && (+product?.last_price > +product?.price &&+product.quantity_last_price > 0)">
                                                    <span class="mb-2 offer-data"><b>{{'Reduced
                                                            discount'
                                                            |
                                                            translate}} {{
                                                            getDiscountPercentage(product?.last_price,product?.quantity_last_price)+
                                                            "%"
                                                            }}</b>
                                                    </span>
                                                </div>
                                                <div class="offer-data-container1"
                                                    *ngIf="product?.price > 0 && product.quantity_last_price !=null  ">
                                                    <span class="bg-black offer-data"><b>{{'quantitydiscount'
                                                            |
                                                            translate}}
                                                        </b>
                                                    </span>
                                                </div>
                                            </td>

                                            <td style="width: 115px;">

                                                <div class="offer-data-container2">
                                                    <div class="quantity-box" *ngIf="product.active == 2">
                                                        <span class="text-danger">
                                                            {{ "Inventory is in progress" | translate }}
                                                        </span>
                                                    </div>

                                                    <div class="quantity-box" *ngIf="product.active != 2">
                                                        <button [disabled]="disabledplus" data-type="plus"
                                                            (click)="increment(product , qtn , loader)">
                                                            <i class="ti-plus"></i>
                                                        </button>
                                                        <input type="text" name="quantity" #qtn
                                                            [(ngModel)]="product.quantity">
                                                        <div class="loader-block d-none" #loader>
                                                            <img src="assets/images/loader.gif" width="30px"
                                                                height="30px">
                                                        </div>
                                                        <button [disabled]="disabledminus" data-type="minus"
                                                            (click)="decrement(product , qtn , loader  )">
                                                            <i class="ti-minus"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="offer-data-container1"
                                                    *ngIf="(product?.offer_id > 0) &&(product?.last_price == null || product?.last_price == ''  ) ">

                                                </div>
                                                <div class="offer-data-container1"
                                                    *ngIf="(product?.last_price > 0) && (+product?.last_price > +product?.price &&product.quantity_last_price ==null)">

                                                </div>
                                                <div class="offer-data-container1"
                                                    *ngIf="(product?.last_price > 0) && (+product?.last_price > +product?.price &&+product.quantity_last_price > 0)">

                                                </div>
                                                <div class="offer-data-container1"
                                                    *ngIf="product?.price > 0 && product.quantity_last_price !=null  ">

                                                </div>
                                            </td>

                                            <td style="text-align: center !important; width: 115px;">
                                                <div class="offer-data-container2">
                                                    <h2 class="td-color">
                                                        {{ ((product.price | discount:product) * product.quantity) *
                                                        productService?.Currency.price | number:'1.3-3' }}
                                                        {{productService?.Currency.name}}
                                                    </h2>
                                                </div>
                                                <div class="offer-data-container1"
                                                    *ngIf="(product?.offer_id > 0) &&(product?.last_price == null || product?.last_price == ''  ) ">
                                                </div>
                                                <div class="offer-data-container1"
                                                    *ngIf="(product?.last_price > 0) && (+product?.last_price > +product?.price &&product.quantity_last_price ==null)">
                                                    <span *ngIf="product?.price > 0 && (product?.last_price > 0) "
                                                        class="d-block discount-tag text-disc">
                                                        -{{
                                                        getSubtraction(product?.last_price,product?.price,product?.quantity) | number:'1.3-3'}}
                                                        {{productService?.Currency.name}}
                                                    </span>
                                                </div>
                                                <div class="offer-data-container1"
                                                    *ngIf="(product?.last_price > 0) && (+product?.last_price > +product?.price &&+product.quantity_last_price > 0)">
                                                    <span *ngIf="product?.price > 0 && (product?.last_price > 0) "
                                                        class="d-block discount-tag text-disc">
                                                        -{{
                                                        getSubtraction(product?.last_price,product?.quantity_last_price,product?.quantity) | number:'1.3-3'}}
                                                        {{productService?.Currency.name}}
                                                    </span>
                                                </div>
                                                <div class="offer-data-container1"
                                                    *ngIf="product?.price > 0 && product.quantity_last_price !=null  ">
                                                    <span *ngIf="product?.price > 0 && +product.quantity_last_price > 0"
                                                        class="d-block discount-tag text-disc">
                                                        -{{
                                                        getSubtraction(product?.quantity_last_price,product?.price,product?.quantity) | number:'1.3-3'}}
                                                        {{productService?.Currency.name}}
                                                    </span>

                                                </div>

                                            </td>
                                            <td style="text-align:center; width: 60px;">
                                                <div class="offer-data-container2">

                                                </div>
                                                <a href="javascript:void(0)"
                                                    (click)="removeItem(product, cart_products['id'] , cart_products)"
                                                    *ngIf="(product['invoice_edit_id'] == '0') || ( product.active != 2 && product['invoice_edit_id'] != '0' ) "
                                                    class="icon delete-item-icon-block">
                                                    <i class="fa fa-trash" aria-hidden="true"></i>
                                                </a>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                            <div class="col-sm-4">

                                <div class="cart_confirm">
                                    <h2 class="title">{{'order summary' | translate}}</h2>


                                    <div class="coupons-checker-layout">
                                        <div class="coupons-upper-text-block">
                                            <span class="coupon-question">
                                                {{'got a coupon' | translate}}
                                            </span>
                                            <span class="coupon-showall" *ngIf="!showCouponsList"
                                                (click)="showCouponsList = true">
                                                {{'show coupons' | translate}}
                                            </span>
                                            <!-- <i class='ti-close' *ngIf="showCouponsList" style="cursor: pointer"
                                (click)="showCouponsList = false"></i> -->

                                            <i *ngIf="showCouponsList" (click)="showCouponsList = false"
                                                style="cursor: pointer" class="fa fa-times" aria-hidden="true">
                                            </i>

                                        </div>
                                        <div class="coupons-list-all" *ngIf="showCouponsList">

                                            <span class="error-text" *ngIf="activeCouponesList.length == 0">
                                                {{'no coupones found' | translate}}
                                            </span>


                                            <div class="coupone-block"
                                                *ngFor="let coupone of activeCouponesList; let index = index">
                                                <div class="start-side">

                                                    <div class="coupon-title">
                                                        {{coupone.title}}
                                                    </div>
                                                    <div class="code-box">
                                                        {{coupone.code}}
                                                    </div>

                                                </div>
                                                <div class="mid-side" *ngIf="getCouponeAccordionStatus(index)">

                                                    <span class="sub-title" [innerHTML]="coupone.description">

                                                    </span>

                                                </div>
                                                <div class="end-side">

                                                    <div class="more-details" (click)="openCouponeDescription(index)">
                                                        <span *ngIf="!getCouponeAccordionStatus(index)">
                                                            {{'more details' | translate}}
                                                        </span>
                                                        <span *ngIf="getCouponeAccordionStatus(index)">
                                                            {{'hide details' | translate}}
                                                        </span>
                                                    </div>
                                                    <div class="confirm-coupon"
                                                        (click)="confirmCouponToInput(coupone.code,cart_products['id'])">
                                                        {{'click to confirm' | translate}}</div>

                                                </div>
                                            </div>
                                        </div>

                                        <div class="coupons-checker-block">
                                            <input type="text" [ngClass]="isCouponAdded ? 'coupon-added' : ''"
                                                placeholder="{{'enter code here' | translate}}"
                                                [readonly]="isCouponAdded" [(ngModel)]="codeValueCoupon" id="fname"
                                                name="fname">
                                            <div class="check-coupon" (click)="addCoupon(codeValueCoupon,cart_products['id'])" *ngIf="!isCouponAdded">
                                                {{'activate' | translate}}
                                            </div>
                                            <div class="check-coupon" (click)="deleteCoupon(cart_products['id'])" *ngIf="isCouponAdded">
                                                <i class="ti-trash"></i>
                                            </div>

                                        </div>

                                    </div>
                                    <ul class="cart-info-details"
                                    *ngIf="!cartInfoDetailsLoading">
                                        <li class="">
                                            <span> {{cart_products['total_product_count'] }} {{'item' | translate}} : </span>
                                            <span>{{ cart_products['total_price'] | number:'1.3-3' }} {{productService?.Currency.name}}</span>
                                        </li>
                                        <li class="td-color" *ngIf="cart_products['total_discount'] >0">
                                            <span>{{'Discounts' | translate}} : </span>
                                            <span>{{ cart_products['total_discount'] | number:'1.3-3' }}
                                                {{productService?.Currency.name}}</span>
                                        </li>
                                        <li class="td-color" *ngIf="cart_products['total_quantity_discount']>0">
                                            <span>{{'quantitydiscount' | translate}} : </span>
                                            <span>{{ cart_products['total_quantity_discount'] | number:'1.3-3'}}
                                                {{productService?.Currency.name}}</span>
                                        </li>
                                        <li class="td-color" *ngIf="codeValueCoupon">
                                            <span>{{'coupon discount' | translate}} ({{codeValueCoupon}}) : </span>
                                            <span>{{ cart_products['total_coupon_discount'] | number:'1.3-3'}}
                                                {{productService?.Currency.name}}</span>
                                        </li>
                                        <li class="">
                                            <span>{{'Total-final' | translate}} : </span>
                                            <span>{{ (cart_products['total_price'] - cart_products['total_coupon_discount'] -
                                                cart_products['total_discount'] - cart_products['total_quantity_discount']) | number:'1.3-3'}}
                                                {{productService?.Currency.name}}</span>
                                        </li>
                                    </ul>
                                    <div class="loader-block" *ngIf="cartInfoDetailsLoading">
                                        <img src="assets/images/loader.gif">
                                    </div>
                                    <div class="cart-buttons d-flex justify-content-center"
                                        *ngIf='cart_products'>
                                        <!-- <div class="mx-2">
                                            <a [routerLink]="['']" class="btn btn-solid">{{'continue shopping' |
                                                translate}}</a>
                                        </div> -->
                                        <div class="checkout-btn w-100">
                                            <a (click)="isLogin()" class="btn btn-solid w-100">{{'checkout' |
                                                translate}}</a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>

        <div *ngIf='products?.length == 0 && !loading'>
            <div class="text-center col-sm-12 empty-cart-cls">
                <img src="assets/images/icon-empty-cart.png" class="mb-4 img-fluid">
                <h3><strong>{{'Your Cart is Empty' | translate}}</strong></h3>
                <div class="row wishlist-buttons">
                    <div class="text-center col-12">
                        <a [routerLink]="['/']" class="btn btn-solid">{{'continue shopping' |
                            translate}}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--section end-->

<!-- DELETE INVOICE MODAL -->
<ng-template class="theme-modal" #newsletter let-modal>
    <div class="modal-content">
        <div class="modal-body modal7" style="background-image: unset;">
            <div class="p-0 container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="modal-bg">
                            <button type="button" class="close" aria-label="Close"
                                (click)="modal.dismiss('Cross click')">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <div class="offer-content" *ngIf="step1">
                                <div class="circle-icon">
                                    <i class="ti-info-alt"></i>

                                </div>
                                <h2>{{'Delete' | translate}} </h2>
                                <h4> {{'Do you want to cancel the order number' | translate}}
                                    {{cart_products['invoice_edit_id']}}</h4>
                                <br>

                                <div class="modal-footer">
                                    <button type="button" class="mr-auto btn btn-primary"
                                        (click)="cancelInvoice(cart_products['invoice_edit_id'])">
                                        Yes
                                    </button>
                                    <button type="button" class="btn btn-secondary" aria-label="Close"
                                        (click)="modal.dismiss('Cross click')">
                                        No
                                    </button>
                                </div>
                            </div>

                            <div class="offer-content" *ngIf="step2">

                                <h2>{{'Delete' | translate}} </h2>

                                <div class="category-image">
                                    <img src="assets/images/slider/loader12.gif" alt="" class="w-auto">
                                </div>

                                <br>
                                <div class="modal-footer">
                                    <br>
                                    <br>
                                </div>
                            </div>

                            <div class="offer-content" *ngIf="step3">
                                <div class="circle-icon-check">
                                    <i class="fa fa-check-circle" aria-hidden="true"></i>
                                </div>

                                <h2 class="text-success">{{'Delete Order Successfully' | translate}}</h2>

                                <br>
                                <div class="modal-footer">
                                    <br>
                                    <button type="button" class="mr-auto btn btn-primary" aria-label="Close"
                                        (click)="modal.dismiss('Cross click')">Ok</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<section #login class="full-sidebar" *ngIf="loginclicked">
    <div class="shadow login-sidebar">
        <i class="ti-close login-icon" (click)="close()"></i>
        <app-fast-login (step5Reached)="onStep5Reached()"
            (stepSuccessReached)="onstepSuccessReached()"></app-fast-login>
    </div>
</section>