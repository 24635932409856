import { Component, Inject, OnInit, PLATFORM_ID } from "@angular/core";
import { NavService, Menu } from "../../services/nav.service";
import { Router } from "@angular/router";
import { CategoriesService } from "./../../services/categories";
import { CommonService } from "../../../shared/services/common.service";
import { UserService } from "../../../shared/services/user.service";
import { isPlatformBrowser, isPlatformServer } from "@angular/common";
declare var jquery: any;
declare var $: any;

@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"],
})
export class MenuComponent implements OnInit {
  public menuItems: Menu[] = [];
  public categories: any[] = [];
  public children: any[] = [];
  public childrenchildren: any[] = [];

  constructor(
    private router: Router,
    public user: UserService,
    public navServices: NavService,
    private Commen: CommonService,
    private categoryService: CategoriesService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.navServices.items.subscribe(
      (menuItems) => (this.menuItems = menuItems)
    );
    this.router.events.subscribe((event) => {
      this.navServices.mainMenuToggle = false;
    });
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.getMainCategories();
    }
  }

  getMainCategories() {
    this.categoryService?.getMainCategories().subscribe((data) => {
      //console.log"HERE IS THE DATA",data);
      if (data.data) {
        this.categories = data.data;
        console.log("cat", this.categories);
      const processCategory = (category: any): Menu => {
        let menu: Menu = {
          path: `/shop/category/${category.id}/${category.ser_name.split(" ").join("-")}`,
          title: category.ser_name,
          type: "link",
          active: false,
          children: [],
        };

        if (category.subServices && category.subServices.length > 0) {
          menu.children = []; 
          for (let subService of category.subServices) {
            const childMenu = processCategory(subService);
            menu.children.push(childMenu); 
          }
        } else {
          menu.children = undefined;
        }

        return menu;
      };

      // Process each top-level category using the recursive function
      for (let category of this.categories) {
        const menu = processCategory(category);
        this.children.push(menu);
      }
      console.log(this.children);
      var obj0: Menu = {
        path: "",
        title:
          localStorage.getItem("front-lang") == "en"
            ? "home"
            : "الصفحة الرئيسية",
        type: "link",
      };
      var obj1: Menu = {
        title:
          localStorage.getItem("front-lang") == "en"
            ? "Categories"
            : "الأقسام",
        type: "sub",
        active: false,
        children: this.children
      };
      // page about us
      var obj3: Menu = {
        path: "/elements/trademarks",
        title:
          localStorage.getItem("front-lang") == "en"
            ? "Brands"
            : "العلامات التجارية",
        type: "link",
      };

        this.menuItems = [];
        this.menuItems.push(obj0);
        this.menuItems.push(obj1);
        data.famous_trade_marks?.length > 0 ? this.menuItems.push(obj3) : '';
        console.log("menu", this.menuItems);
      }
      // else if(){

      // }
      else{

        var obj0: Menu = {
          path: "",
          title:
            localStorage.getItem("front-lang") == "en"
              ? "home"
              : "الصفحة الرئيسية",
          type: "link",
        };
        var obj3: Menu = {
          path: "/elements/trademarks",
          title:
            localStorage.getItem("front-lang") == "en"
              ? "Brands"
              : "العلامات التجارية",
          type: "link",
        };
  
          this.menuItems = [];
          this.menuItems.push(obj0);
          this.menuItems.push(obj3);
          console.log("menu", this.menuItems);
      }
    });
  }

  mainMenuToggle(): void {
    this.navServices.mainMenuToggle = !this.navServices.mainMenuToggle;
  }

  // Click Toggle menu (Mobile)
  toggletNavActive(item) {
    item.active = !item.active;
  }

  ngAfterViewInit() {}
}
