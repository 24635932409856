import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonService } from 'src/app/shared/services/common.service';
import SwiperCore, {
  FreeMode,
  Navigation,
  Pagination,
  SwiperOptions,
  Thumbs,
} from "swiper";
// install Swiper modules
SwiperCore.use([FreeMode, Navigation, Thumbs, Pagination]);

@Component({
  selector: 'app-brands-home-slider',
  templateUrl: './brands-home-slider.component.html',
  styleUrls: ['./brands-home-slider.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BrandsHomeSliderComponent implements OnInit {
  @Input() itemsList: any[] = [];
  @Input() itemsListDesktop: any[] = [];
  thumbsSwiper: any;

  servicesUrlimage = this.commonService._ImageUrl + "services/";
  brandUrlimage = this.commonService._ImageUrl + 'trademarks/';

  categoriesSliderConfig: SwiperOptions = {
    slidesPerView: "auto",
    spaceBetween: 10,
    // navigation: true,

    scrollbar: { draggable: true },
  };

  constructor(private commonService: CommonService) {}

  ngOnInit(): void {}

}
