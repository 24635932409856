<!-- BRANDS SLIDER -->
<swiper [config]="categoriesSliderConfig" class="brands-home-swiper">
    <ng-template class="single-slide-mobile" *ngFor="let item of itemsList ; let i = index; let even = even; let odd = odd" swiperSlide>
        <div class="single-item-block">
            <div class="item" [routerLink]="['/shop/trademarkes/'+item[0]?.id+'/'+item[0]?.title.split(' ').join('-')]">
                <img [src]="brandUrlimage+item[0]?.image" [alt]="'brand_name'" class="">
            </div>
            <span class="brand-title">
                {{item[0]?.title}}
            </span>
        </div>
        
        <div class="single-item-block">
            <div class="item" *ngIf="item[1]?.id" [routerLink]="['/shop/trademarkes/'+item[1]?.id+'/'+item[1]?.title.split(' ').join('-')]">
                <img [src]="brandUrlimage+item[1]?.image" [alt]="'brand_name'" class="">
            </div>
            <span class="brand-title">
                {{item[1]?.title}}
            </span>
        </div>
        
    </ng-template>
    <ng-template class="single-slide-desktop" *ngFor="let item of itemsListDesktop ; let i = index; let even = even; let odd = odd" swiperSlide>
        <a class="single-item-block" [routerLink]="['/shop/trademarkes/'+item[0].id+'/'+item[0].title.split(' ').join('-')]">
            <div class="item">
                <img [src]="brandUrlimage+item[0]?.image" [alt]="'brand_name'" class="">
            </div>
            <span class="brand-title">
                {{item[0]?.title}}
            </span>
        </a>
        
    </ng-template>
</swiper>