<div class="container mobile-container mt-2">

  <div class="row">

      
  <div class="col-sm-12">

    <div class="mobile-cart-layout">
      <div class="single-product-layout "
          *ngFor="let product of products; let i = index">
          <a class="cart-link" *ngIf="product?.images?.length > 0"
              [routerLink]="['/shop/product/', product?.product_id ,product.meta_url ? product.meta_url?.split(' ').join('-') : removeBrackets(product.product_name).split(' ').join('-')]">
              <img *ngIf="product.images.length > 0"
                  [src]="urlimage+product.image" [alt]="product?.product_name" (error)="handleImageError($event.target)" class="img-fluid" >

                <img *ngIf="product.image && product.images[0]['type'] === 'video'"
                  src="assets/images/video.png">

                <img *ngIf="product.images.length == 0" src="assets/images/product/placeholder.jpg"
                      [alt]="product?.product_name" >
          </a>
          <a class="cart-link" [routerLink]="['/shop/product/', product?.product_id , product.meta_url  !== null ? product.meta_url?.split(' ').join('-') : removeBrackets(product.product_name).split(' ').join('-')]"
              *ngIf="product?.images?.length == 0">
              <img src="assets/images/product/placeholder.jpg" class="product-image">
          </a>

          <div class="title-quantity-price-block">
              <div class="title">
                  <a [routerLink]="['/shop/product/', product?.product_id ,product.meta_url ? product.meta_url.split(' ').join('-') : removeBrackets(product.product_name).split(' ').join('-')]">
                      {{ product?.product_name | titlecase }}
                  </a>
              </div>
              <div class="quantity-price-block ">
                  <div class="price-block">
                      <span class="price">
                          {{ ((product?.price | discount:product)  *
                          productService?.Currency.price) | number:'1.3-3'
                          }}
                          {{productService?.Currency.name}}
                      </span>
                      <del *ngIf="product?.price > 0 && (product?.last_price > 0)"
                          class="old-price">{{
                          product?.last_price | number:'1.3-3' }} {{productService?.Currency.name}}</del>
                  </div>
                  <div class="quantity-block" *ngIf="product?.active != 2">
                      <div class="quantity-number-block">
                          <span class="quantity-number" #qtn>{{product.quantity}}</span>
                      </div>
                  </div>
                  <div class="total-price-block">
                      <span class="total-price">
                          {{ (((product?.price | discount:product) * product?.quantity) *
                          productService?.Currency.price ) | number:'1.3-3'}}
                          {{productService?.Currency.name}}
                      </span>
                      <span *ngIf="product?.price > 0 && (product?.last_price > 0)"
                          class="discount-price">
                          ({{'discount' | translate}} {{
                          getSubtraction(product?.last_price,product?.price,product?.quantity) | number:'1.3-3'}}
                          {{productService?.Currency.name}})
                      </span>
                  </div>
              </div>

          </div>

      </div>
  </div>


    <table class="table cart-table table-responsive-xs">
      <thead>
          <tr class="table-head">
              <th scope="col" class="img-head">{{'image' | translate}}</th>
              <th scope="col" class="title-head">{{'Product Name' | translate}} </th>
              <th scope="col">{{'Price' | translate}}</th>
              <th scope="col">{{'quantity' | translate}}</th>
              <th scope="col">{{'Total' | translate}}</th>
          </tr>
      </thead>
      <tbody>

          <!-- Cart Items Loop -->
          <tr *ngFor="let product of products">
              <td class="image-cell">
                  <a [routerLink]="['/shop/product/', product?.product_id ,product.meta_url ? product.meta_url?.split(' ').join('-') : removeBrackets(product.product_name).split(' ').join('-')]"
                      >
                      <img *ngIf="product?.images.length > 0"
                      [src]="urlimage+product.image" [alt]="product?.product_name" (error)="handleImageError($event.target)" >

                      <img *ngIf="product.image && product.images[0]['type'] === 'video'"
                          src="assets/images/video.png">

                      <img *ngIf="product.images.length == 0" src="assets/images/product/placeholder.jpg"
                          [alt]="product?.product_name" >

                  </a>
              </td>
              <td>
                  <a [routerLink]="['/shop/product/', product?.product_id ,product.meta_url   ? product.meta_url?.split(' ').join('-') : removeBrackets(product.product_name).split(' ').join('-')]">{{
                      product?.product_name
                      |
                      titlecase }}</a>


              </td>
              <td>
                  <h2 class="td-color">
                      {{ ((product.price | discount:product) *
                      productService?.Currency.price) | number:'1.3-3'
                      }}
                      {{productService?.Currency.name}}
                      <del *ngIf="product?.price > 0 && (product?.last_price > 0) && (product?.quantity_last_price == 0)"
                          class="d-block pt-1">{{
                          product?.last_price | number:'1.3-3' }} {{productService?.Currency.name}}</del>
                      <del *ngIf="product?.quantity_last_price != 0"
                          class="d-block pt-1">{{
                          product?.quantity_last_price | number:'1.3-3' }} {{productService?.Currency.name}}</del>

                  </h2>
              </td>
              <td>
                  <div class="quantity-box" *ngIf="product.active != 2">
                      <p style="font-weight: 600;">
                        {{product.quantity}}
                      </p>
                  </div>
              </td>

              <td>
                  <h2 class="td-color">
                      {{ (((product.price | discount:product) * product.quantity) *
                      productService?.Currency.price ) | number:'1.3-3'}} {{productService?.Currency.name}}

                  </h2>
                  <span *ngIf="product?.price > 0 && (product?.last_price > 0 || product?.quantity_last_price > 0)  "
                      class="d-block pt-1 discount-tag">
                      ({{'discount' | translate}} {{ 
                      product?.quantity_last_price == 0 ?
                        getSubtraction(product?.last_price,product?.price,product?.quantity) :getSubtraction(product?.quantity_last_price,product?.price,product?.quantity) 
                      | number:'1.3-3'}}
                      {{productService?.Currency.name}})
                      <!-- (خصم 10 جنيه) -->
                      <!-- ({{'discount' | translate}}) -->
                  </span>

              </td>
          </tr>

      </tbody>
    </table>

  </div>
  </div>
















  <!-- <div class="img-block">
    <a [routerLink]="['/shop/product/', product.product_id]" *ngIf="product.images.length > 0">
      <img [src]="urlimage+product.image" [alt]="product?.product_name" *ngIf="product.images[0]['type'] === 'image'" class="img-fluid">
      <img src="assets/images/video.png" *ngIf="product.images[0]['type'] === 'video'" class="img-fluid">
    </a>
    <a [routerLink]="['/shop/product/', product.product_id]" *ngIf="product.images.length == 0">
      <img src="assets/images/product/placeholder.jpg" class="img-fluid">
    </a>
  </div>
  <div class="product-details-block">
    <a class="title1" [routerLink]="['/shop/product/', product.product_id]">
      {{product.product_name | truncate:60}}
    </a>

    <div class="quantity-block">
      <span class="quantity-label">{{'quantity' | translate}}: </span>
      <span>
        {{product.quantity}}
      </span>
    </div>
    <div class="price-block">
      <span class="price-label">
        {{'Price' | translate}}:
      </span>
      <span class="last-price" *ngIf="product.last_price && product.last_price != 0">
        {{product.last_price}}
      </span>
      <span>{{product.price}} {{productService?.Currency.name}}</span>
    </div>
    <div class="total-price-block">
      <span class="total-price-label">
        {{'Total-final' | translate}}:
      </span>
      <span class="last-total-price" *ngIf="product.last_price && product.last_price != 0">
        {{product.last_price * product.quantity}}
      </span>
      <span>
        {{product.price * product.quantity}} {{productService?.Currency.name}}
      </span>
    </div>



  </div> -->
</div>
