<div class="breadcrumb-section">
    <div class="container">
        <div class="row">

            <div class="col-sm-12">
                <nav aria-label="breadcrumb" class="theme-breadcrumb">
                    <div class="breadcrumb">
                        <a [routerLink]="'/'" class="">
                            {{'home' | translate}}
                        </a>
                        <a class=""
                            [routerLink]="['/shop/category' , breadCrumb.id , breadCrumb.ser_name?.split(' ').join('-')]"
                            *ngFor="let breadCrumb of breadCrumblist ;  let isFirst = first ; let isLast = last">>
                            {{breadCrumb.ser_name | translate}} <span *ngIf="!isFirst || isLast">></span></a>
                        <a class="">
                            {{breadCrumbProductName | translate}}
                        </a>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</div>


<!-- <div class="page-title">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <h1>{{ product?.name }}</h1>
            </div>
        </div>
    </div>
</div> -->

<!-- section start -->
<section class="pt-0 section-b-space">
    <div class="collection-wrapper">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-sm-12 col-xs-12">
                    <div class="container-fluid">

                        <div class="row">
                            <div class="col-lg-9 main-section-start">
                                <div class="slider-description-main-section">
                                    <button class="btn wishlist-btn wish-mobile" (click)="addToWishlist(product)">
                                        <i class="fa fa-heart fa-2x"
                                            [ngClass]="isFavourit ? 'text-danger' : 'text-icon'"></i>
                                    </button>
                                    <div class="p_slider">
                                        <span class="lable5" *ngIf="product?.attachment_id> 0"
                                            [class]="Common.isRtl ? 'label-ar' : 'label-en'">
                                            <b [style.background]="product['attachment_color']"
                                                [style.color]="Common.tinyColor(product['attachment_color'])">
                                                {{product?.attachment_name}}
                                            </b>
                                        </span>
                                        <!-- <span class="lable6" *ngIf="product?.offer_id> 0 ">
                                            <b>{{product?.offer_data['title']}}</b>
                                        </span> -->


                                        <!-- Main Swiper -->
                                        <swiper [pagination]="true" class="mainProductSlider1 "
                                            *ngIf="product?.images?.length > 0" [thumbs]="{ swiper: thumbsSwiper }"
                                            #mainProductSwiper>
                                            <ng-template class="h-100" *ngFor="let image of
                                                productimages; index as i" swiperSlide let-data>
                                                <div *ngIf="image" class="h-100">
                                                    <img *ngIf="image.type === 'image'" class="img-fluid h-100"
                                                        (error)="handleImageError($event.target)"
                                                        [src]="urlimage+image.name" alt="{{ product?.name }}"
                                                        class="img-fluid" [title]="">

                                                    <video controls [autoplay]="data.isActive"
                                                        *ngIf="image.type === 'video' && data.isActive"
                                                        class="img-fluid">
                                                        <source [src]="urlimage+image.name">
                                                    </video>
                                                </div>
                                            </ng-template>
                                        </swiper>

                                        <swiper [pagination]="true" class="mainProductSlider1"
                                            *ngIf="product?.images?.length == 0" [thumbs]="{ swiper: thumbsSwiper }">
                                            <ng-template swiperSlide let-data>
                                                <img [defaultImage]="'assets/images/product/placeholder.jpg'"
                                                    [lazyLoad]="'assets/images/product/placeholder.jpg'"
                                                    class="img-fluid" [title]="">
                                            </ng-template>
                                        </swiper>

                                        <!-- Small Swiper -->
                                        <swiper (swiper)="thumbsSwiper = $event" [spaceBetween]="10"
                                            *ngIf="product?.images?.length > 0" [slidesPerView]="'auto'"
                                            [freeMode]="true" [watchSlidesProgress]="true"
                                            class="productImagesSideSlider">

                                            <ng-template *ngFor="let image of productimages; index as i" swiperSlide>

                                                <img *ngIf="image.type === 'image'"
                                                    (error)="handleImageError($event.target)"
                                                    [src]="urlimage+image.name" alt="{{ product?.name }}"
                                                    class="img-fluid">

                                                <video *ngIf="image.type === 'video'" class="img-fluid">
                                                    <source src="{{urlimage+image.original_name}}">
                                                </video>

                                            </ng-template>

                                        </swiper>

                                        <swiper (swiper)="thumbsSwiper = $event" [spaceBetween]="10"
                                            *ngIf="product?.images?.length == 0" [slidesPerView]="'auto'"
                                            [freeMode]="true" [watchSlidesProgress]="true"
                                            class="productImagesSideSlider">

                                            <ng-template swiperSlide>
                                                <img [lazyLoad]="'assets/images/product/placeholder.jpg'"
                                                    class="img-fluid">
                                            </ng-template>

                                        </swiper>

                                        <!-- <owl-carousel-o [options]="ProductDetailsMainSliderConfig" #owlCar
                                            class="product-slick main-img">
                                            <ng-container *ngFor="let image of
                                            productimages; index as i">
                                                <ng-template carouselSlide [id]="i">
                                                    <div class="">
                                                        <img [defaultImage]="'assets/images/product/placeholder.jpg'"
                                                            [lazyLoad]="urlimage+image.name" alt="{{ product?.name }}"
                                                            class="img-fluid">
                                                    </div>
                                                </ng-template>
                                            </ng-container>
                                        </owl-carousel-o> -->


                                        <!-- <div class="p-0 col-12 side-product-images" *ngIf="productimages.length> 1">
                                            <div class="slider-nav">
                                                <owl-carousel-o [options]="ProductDetailsThumbConfig"
                                                    class="product-slick">
                                                    <ng-container *ngFor="let image
                                                    of productimages; index as
                                                    i">
                                                        <ng-template carouselSlide [id]="i">
                                                            <div class="owl-thumb" [class.active]="i ==
                                                            activeSlide">
                                                                <img [defaultImage]="'assets/images/product/placeholder.jpg'"
                                                                    [lazyLoad]="urlimage+image.name"
                                                                    alt="{{ product?.name }}" class="img-fluid" (click)="owlCar.to(activeSlide=
                                                                i.toString())">
                                                            </div>
                                                        </ng-template>
                                                    </ng-container>
                                                </owl-carousel-o>
                                            </div>
                                        </div> -->
                                    </div>

                                    <div class="title-description-section">
                                        <span *ngIf="product?.price> 0 && (product?.last_price > 0)"
                                            class="pt-1 discout">
                                            {{'discount' | translate}}
                                            {{ getDiscountPercentage(product?.last_price,product?.price)
                                            + "%" }}
                                        </span>
                                        <div class="title">
                                            <h1 style="line-height: normal;" class="font-title font-weight-bold">{{
                                                product?.name }}</h1>
                                        </div>

                                        <div class="desktop-descrition list-style">
                                            <ul class="product-status d-flex flex-column align-items-start ">
                                                <li *ngIf="(product?.store_amounts > 0 && product?.price > 0 )">
                                                    <span class="info-key-title">
                                                        {{'product Status' | translate}} :
                                                    </span>
                                                    <span class="text-success">
                                                        {{'available' | translate}}
                                                    </span>
                                                </li>
                                                <li
                                                    *ngIf="(product?.store_amounts == 0 && product?.price == 0) ||(product?.store_amounts == 0 && product?.price > 0) ">
                                                    <span class="info-key-title">
                                                        {{'product Status' | translate}} :
                                                    </span>
                                                    <span class="text-danger">
                                                        {{'out of stock' | translate}}
                                                    </span>
                                                </li>

                                                <li *ngIf="(product?.store_amounts> 0 && product?.price == 0) ">
                                                    <span class="info-key-title">
                                                        {{'product Status' | translate}} :
                                                    </span>
                                                    <span class="soon-status">
                                                        {{'Soon' | translate}}
                                                    </span>
                                                </li>

                                                <li *ngIf="product?.product_code">
                                                    <span class="info-key-title">
                                                        {{'product code' | translate}} :
                                                    </span>
                                                    <span style="color: #222222;">
                                                        {{product?.product_code}}
                                                    </span>
                                                </li>

                                                <li *ngIf="product?.product_barcode">
                                                    <span class="info-key-title">
                                                        {{'barcode' | translate}} :
                                                    </span>
                                                    <span style="color: #222222;">
                                                        {{product?.product_barcode}}
                                                    </span>
                                                </li>

                                                <li *ngIf="product?.trademark_title" class="p-0">
                                                    <span class="info-key-title">
                                                        {{'brand' | translate}} :
                                                    </span>
                                                    <a style="text-decoration: underline;"
                                                        [routerLink]="['/shop/trademarkes/'  , product?.trademark_id , product?.trademark_title?.split(' ').join('-')]">
                                                        <span style="color: #222222;">
                                                            {{product?.trademark_title}}
                                                        </span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="desktop-choose-options" *ngIf="optionsData.length>1">
                                            <hr>
                                            <span class="mb-3 product-title added-title">
                                                {{'Choose an option'|translate}}
                                            </span>
                                            <div class="flex-wrap gap-3 d-flex">
                                                <div (click)="chooseOptions(i,item)" class="option-choose-data"
                                                    [class.active]="i==optionChekced"
                                                    *ngFor="let item of optionsData let first=first let i =index">
                                                    <span>{{item.name}}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="desktop-descrition">
                                            <hr *ngIf="productFormShapesDetails.length >0">
                                            <span class="mb-2 product-title added-title description-desktop"
                                                *ngIf="productFormShapesDetails.length >0">
                                                {{"Specifications" | translate}}:
                                            </span>
                                            <div class="list-style w-100">
                                                <ul class="product-status d-flex flex-column align-items-start">
                                                    <li *ngFor="let form of productFormShapesDetails; let index=index; let last=last"
                                                        class="flex-nowrap d-flex" [class.p-0]="last">
                                                        <i class="fa fa-solid fa-circle"></i>
                                                        <span class="info-key-title" style="white-space: nowrap;">
                                                            {{form.form_name | translate}}
                                                            <i class="fa fa-exclamation-circle pointer-event "
                                                                *ngIf="!form.form_shape_id"
                                                                (click)="getNoteDetails(3)"></i>
                                                            :&nbsp;
                                                        </span>
                                                        <span style="color: #222222;"
                                                            *ngIf="form.type != 'm_select' && form.type != 'text' &&form.type">
                                                            <a style="text-decoration: underline;"
                                                                [routerLink]="['/pages/formshapes/' + form.form_value]">{{form.form_value}}</a>

                                                        </span>
                                                        <span style="color: #222222;" *ngIf="form.type == 'text'">
                                                            <a>{{form.form_value}}</a>
                                                        </span>
                                                        <span style="color: #222222;" *ngIf="!form.type">
                                                            <a>{{form.form_value}}</a>
                                                        </span>

                                                        <!-- <span style="color: #222222;" class="flex-wrap d-flex">
                                            
                                                                                                </span> -->
                                                        <div class="flex-wrap d-flex ">
                                                            <ng-container *ngIf="form.type == 'm_select'">
                                                                <span style="color: #222222;"
                                                                    *ngFor="let select of multi_select[index]; let s=index; last as isLast">
                                                                    <a style="text-decoration: underline;"
                                                                        [routerLink]="['/pages/formshapes/' + select]">
                                                                        {{select}}
                                                                        <span class="mx-2 d-inline-block"
                                                                            *ngIf="!isLast"> -
                                                                        </span>
                                                                    </a>

                                                                </span>
                                                            </ng-container>
                                                        </div>


                                                    </li>

                                                </ul>
                                            </div>

                                        </div>


                                        <div class="discount-options">
                                            <hr *ngIf="ProductQuantity>=1">
                                            <span class="mb-3 product-title added-title" *ngIf="ProductQuantity >= 1">
                                                {{'Enjoy the quantitative discount'|translate}}
                                            </span>
                                            <ul class="row">
                                                <li class="col-md-10"
                                                    *ngFor="let disc of quantity_discount let d=index">
                                                    <ng-container
                                                        *ngIf="disc.quantity <= this.product?.stores[0]['store_amounts_product'] && +(disc.quantity) > 1">
                                                        <div class="mb-2 cursor-pointer d-flex justify-content-between align-items-center discount-item "
                                                            (click)="addProductDiscountToCart(disc)">

                                                            <div class="d-flex align-items-center">
                                                                <img src="assets/images/hot-deal-ezgif.com-resize.webp"
                                                                    width="30" alt="">
                                                                <p class="px-2 m-0 text-black text-size p-line related-text"
                                                                    *ngIf="disc.purchase_text !='' &&disc.purchase_text!=null">
                                                                    {{disc.purchase_text}}</p>

                                                                <p class="px-2 m-0 text-black text-size p-line related-text"
                                                                    *ngIf="disc.purchase_text=='' ||disc.purchase_text ==null">
                                                                    {{'buy'|translate}}
                                                                    {{disc.quantity}} {{'price'|translate}}
                                                                    {{(disc.quantity*disc.unit_price) | number:'1.3-3'}}
                                                                </p>

                                                            </div>
                                                            <div class="d-flex align-items-center">
                                                                <p class="px-2 m-0 text-black text-size related-text">
                                                                    {{disc.unit_price | number:'1.3-3'}} {{
                                                                    currency?.currency }}</p>
                                                                <p style="text-decoration: line-through;"
                                                                    class="m-0 text-black text-muted text-size related-text"
                                                                    *ngIf="unitPricefor1quantity">
                                                                    {{unitPricefor1quantity | number:'1.3-3'
                                                                    }} {{ currency?.currency }}</p>
                                                            </div>
                                                        </div>
                                                    </ng-container>

                                                </li>
                                            </ul>
                                            <hr *ngIf="related_product?.length >1">

                                            <span class="mb-3 product-title added-title"
                                                *ngIf="related_product?.length >1">
                                                {{'Other options are available'|translate}}
                                            </span>
                                            <ng-container *ngIf="related_product?.length > 1">
                                                <div class="options-container">
                                                    <div class="text-center options-item" [class.active]="index == 0"
                                                        [class.cursor-pointer]="index != 0"
                                                        *ngFor="let prod of related_product; let index=index"
                                                        [routerLink]="index != 0 ? ['/shop/product/', prod.id, prod.meta_url ? prod.meta_url?.split(' ').join('-').replace('+', '') : removeBrackets(prod.product_name)?.split(' ').join('-').replace('+', '')] : null">

                                                        <p class="text-center text-black option_title text-size">
                                                            {{prod.related_details}}</p>
                                                        <div
                                                            *ngIf="(prod.store_amounts > 0 && prod.price > 0 && prod?.stores?.length == 1)">
                                                            <span class="mr-2 text-black text-size"
                                                                [class.text-red]="prod.last_price"
                                                                [class.text-black]=" !prod.last_price">{{prod.price}}
                                                                {{productService?.Currency.name}}</span>
                                                            <span class="mr-2 text-black text-muted text-size"
                                                                style="text-decoration: line-through;"
                                                                *ngIf="prod.last_price">{{prod.last_price}}
                                                                {{productService?.Currency.name}}</span>
                                                        </div>
                                                        <span class="text-stock text-soon text-size"
                                                            *ngIf="(prod.store_amounts == 0 && prod.price == 0) || (prod.store_amounts == 0 && prod.price > 0)">
                                                            {{'Out of stock' | translate}}
                                                        </span>
                                                        <span class="text-soon text-size"
                                                            *ngIf="(prod.store_amounts > 0 && prod.price == 0)">
                                                            {{'Soon' | translate}}
                                                        </span>
                                                    </div>
                                                </div>
                                            </ng-container>

                                            <hr *ngIf="similar_product?.length > 1">

                                            <div class="similar-container container-fit position-relative"
                                                *ngIf="similar_product?.length > 1 && similar_product?.length <3 ">
                                                <h2 class="product-title" [style.color]="calculateTextColor()">
                                                    {{'Products that are frequently purchased together'|translate}}
                                                </h2>
                                                <div class="similar-product d-flex justify-content-center">
                                                    <ng-container
                                                        *ngIf="similar_product?.length >1&&similar_product?.length < 3">
                                                        <div class="d-flex align-items-center related-products-slide1"
                                                            *ngFor="let sProduct of similar_product; let s = index; last as isLast">
                                                            <div class="product-box">
                                                                <div class="product-box-inner">
                                                                    <div class="img-wrapper position-relative">
                                                                        <input type="checkbox"
                                                                            class="form-check product-check"
                                                                            [checked]="sProduct.checked"
                                                                            (change)="toggleProductChecked(s)">
                                                                        <div class="text-center"
                                                                            [class.cursor-pointer]="s != 0"
                                                                            [routerLink]="s != 0 ?  ['/shop/product/', sProduct.id , sProduct.meta_url ? sProduct.meta_url?.split(' ').join('-').replace('+' , '') : removeBrackets(sProduct.product_name)?.split(' ').join('-').replace('+' , '') ] :null">
                                                                            <div class="front">
                                                                                <a>
                                                                                    <div
                                                                                        class="text-center image-frame">
                                                                                        <img [src]="(sProduct.images.length > 0 ? urlimage + sProduct.images[0].original_name : 'assets/images/product/placeholder.jpg')"
                                                                                            [lazyLoad]="urlimage + sProduct.original_name"
                                                                                            class="img-fluid lazy-loading"
                                                                                            (error)="sProduct.images.length = 0; $event.target.src = 'assets/images/product/placeholder.jpg';"
                                                                                            alt="Product Image" />
                                                                                    </div>
                                                                                </a>
                                                                            </div>
                                                                            <h3
                                                                                class="text-center text-black text-size">
                                                                                {{
                                                                                sProduct.product_name
                                                                                }}</h3>
                                                                            <p
                                                                                class="text-center text-black product-price text-size">
                                                                                {{
                                                                                sProduct.price | number:'1.3-3' }} {{
                                                                                productService?.Currency.name }}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <p class="plus"
                                                                [ngStyle]="isLast?{'opacity':'0'}:{'opacity':'1'}">+</p>
                                                        </div>

                                                    </ng-container>

                                                </div>
                                                <button type="button" class="btn w-100"
                                                    [disabled]="selectedProductsCount === 0"
                                                    (click)="!isloading && addSimilarToCart()">
                                                    <h5
                                                        class="flex-wrap cursor-pointer d-flex justify-content-between product-title">
                                                        <span class="product_title">
                                                            {{'addproduct'|translate}} {{selectedProductsCount +"
                                                            "}}{{'to cart'|translate}} </span>
                                                        <span class="product_title">|</span>
                                                        <div>
                                                            <span class="product_title">{{ totalPrice }} {{
                                                                productService?.Currency.name }}</span>
                                                            <span *ngIf="isloading" class="cutom-loader"></span>
                                                        </div>
                                                    </h5>
                                                </button>
                                            </div>
                                            <div class="my-3 similar-container position-relative"
                                                *ngIf="similar_product?.length >=3">
                                                <h2 class="product-title" [style.color]="calculateTextColor()">
                                                    {{'Products that are frequently purchased together'|translate}}
                                                </h2>
                                                <div class="similar-product">

                                                    <swiper [config]="similarProductsSliderConfig">

                                                        <ng-template
                                                            class="d-flex align-items-center related-products-slide1"
                                                            *ngFor="let sProduct of similar_product; let s = index; last as isLast"
                                                            swiperSlide>
                                                            <div class="product-box">
                                                                <div class="product-box-inner">
                                                                    <div class="img-wrapper position-relative">
                                                                        <input type="checkbox"
                                                                            class="form-check product-check"
                                                                            [checked]="sProduct.checked"
                                                                            (change)="toggleProductChecked(s)">
                                                                        <div class="text-center"
                                                                            [class.cursor-pointer]="s != 0"
                                                                            [routerLink]="s != 0 ?  ['/shop/product/', sProduct.id , sProduct.meta_url ? sProduct.meta_url?.split(' ').join('-').replace('+' , '') : removeBrackets(sProduct.product_name)?.split(' ').join('-').replace('+' , '') ] :null">
                                                                            <div class="front">
                                                                                <a>
                                                                                    <div
                                                                                        class="text-center image-frame">
                                                                                        <img [src]="(sProduct.images.length > 0 ? urlimage + sProduct.images[0].original_name : 'assets/images/product/placeholder.jpg')"
                                                                                            [lazyLoad]="urlimage + sProduct.original_name"
                                                                                            class="img-fluid lazy-loading"
                                                                                            (error)="sProduct.images.length = 0; $event.target.src = 'assets/images/product/placeholder.jpg';"
                                                                                            alt="Product Image" />
                                                                                    </div>
                                                                                </a>
                                                                            </div>
                                                                            <h3
                                                                                class="text-center text-black text-size">
                                                                                {{
                                                                                sProduct.product_name
                                                                                }}</h3>
                                                                            <p
                                                                                class="text-center text-black product-price text-size">
                                                                                {{
                                                                                sProduct.price | number:'1.3-3' }} {{
                                                                                productService?.Currency.name }}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <p class="plus"
                                                                [ngStyle]="isLast?{'opacity':'0'}:{'opacity':'1'}">+</p>
                                                        </ng-template>

                                                    </swiper>
                                                    <button type="button" class="btn w-100"
                                                        [disabled]="selectedProductsCount === 0"
                                                        (click)="!isloading && addSimilarToCart()">
                                                        <h5
                                                            class="flex-wrap cursor-pointer d-flex justify-content-between product-title">
                                                            <span class="product_title">
                                                                {{'addproduct'|translate}} {{selectedProductsCount +"
                                                                "}}{{'to cart'|translate}} </span>
                                                            <span class="product_title">|</span>
                                                            <div>
                                                                <span class="product_title">{{ totalPrice }} {{
                                                                    productService?.Currency.name }}</span>
                                                                <span *ngIf="isloading" class="cutom-loader"></span>
                                                            </div>
                                                        </h5>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="frequently-bought" *ngIf="packageData">
                                    <div class="title">
                                        <h2>
                                            {{'frequently bought together' | translate}}
                                        </h2>
                                    </div>
                                    <div class="main">
                                        <div class="products-images-section">
                                            <div style="display: flex"
                                                *ngFor="let product of packageData.products ; let i = index">
                                                <a [routerLink]="'/shop/product/'+ product?.id" target="_blank">
                                                    <div [ngClass]="'single-product-layout'" class="">
                                                        <div class="image-box">
                                                            <img src="{{urlimage+product?.image}}"
                                                                alt="{{ product?.name }}">
                                                            <!-- <img [defaultImage]="'assets/images/product/placeholder.jpg'"
                                                            [lazyLoad]="urlimage+product?.image" alt="{{ product?.name }}"> -->
                                                        </div>
                                                        <div class="price-box">
                                                            <span>
                                                                {{product?.price}} {{productService?.Currency.name}}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </a>
                                                <div class="plus-sign-block" *ngIf="i != 2">
                                                    <i class="ti-plus"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="products-details-section">
                                            <div class="select-product-names-list">
                                                <div class="single-product-list-item"
                                                    *ngFor="let product of packageData.products ; let index = index">
                                                    <a [routerLink]="'/shop/product/'+ product?.id" target="_blank">
                                                        <i class="ti-angle-left"></i>
                                                        <span
                                                            [ngClass]="freqBoughtCheckboxList[index] ? 'selected' : 'not-selected'">
                                                            {{product?.name}}
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="frequent-bought-total-price-block">
                                                <span>
                                                    {{'package price' | translate}}
                                                    <span style="color: var(--theme-default)">

                                                        {{newPackagePrice}} {{productService?.Currency.name}}
                                                    </span>

                                                    {{'instead of' | translate}}
                                                    <span style="text-decoration: line-through;">
                                                        {{packageData.total_price}} {{productService?.Currency.name}}
                                                    </span>

                                                    {{'save' | translate}}
                                                    {{packageData.discount}} {{productService?.Currency.name}}

                                                </span>
                                            </div>
                                            <div class="add-selected-frequent-button-layout">
                                                <div class="add-to-cart-button product-buttons">
                                                    <a href="javascript:void(0)" class="btn btn-animation"
                                                        (click)="addPackageToCart(packageData.products[0], packageData.products[1], packageData.products[2])">
                                                        <i class="fa fa-shopping-cart me-1" aria-hidden="true">
                                                        </i>
                                                        {{'add products to cart' | translate}}
                                                    </a>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="col-lg-3 rtl-text add-to-cart-desktop-section">
                                <div class="product-right ">
                                    <div class="add-to-cart-layout">
                                        <div class="add-to-cart-main">
                                            <div class="price-quantity-block">
                                                <div class="product-price">
                                                    <h3 class="p-3 price-detail"
                                                        *ngIf="(product?.price > 0 && product?.store_amounts > 0)">
                                                        <div class="d-flex">
                                                            <span>
                                                                {{ (product?.price | discount:product) | number:'1.3-3' }}
                                                                {{ currency?.currency }}
                                                            </span>
                                                            <del *ngIf="product?.price > 0 && (product?.last_price > 0)"
                                                                class="mx-3 d-block">
                                                                {{ product?.last_price | number:'1.3-3' }} {{currency?.currency}}
                                                            </del>
                                                        </div>
                                                    </h3>
                                                    <div *ngIf="product?.price> 0 && (product?.last_price > 0)"
                                                        class="pt-1 discount-block">
                                                        {{'discount' | translate}}
                                                        {{ getDiscountPercentage(product?.last_price,product?.price)
                                                        + "%" }}
                                                    </div>
                                                </div>

                                                <div class="product-quantity"
                                                    *ngIf="(product?.store_amounts !== 0 && product?.price !== 0) || (product?.store_amounts !== 0 && product?.price!> 0) ">
                                                    <div *ngIf="(product?.store_amounts !== 0 && product?.price !== 0) || (product?.store_amounts !== 0 && product?.price!> 0) "
                                                        class=" product-description border-product">
                                                        <h6 class="product-title"> {{'quantity' | translate}} </h6>
                                                        <div class="errorOccurred" *ngIf="quantatyMorThanStor">
                                                            {{'quantaty Mor Than Stor' | translate}}
                                                            <br>
                                                            <br>
                                                        </div>

                                                        <!-- <div class="errorOccurred" *ngIf="userNotLogedInError && deviceID">
                                                            {{'please login' | translate}}

                                                            <br>
                                                            <br>
                                                        </div> -->
                                                        <div class="qty-box">
                                                            <div class="input-group">
                                                                <span class="input-group-prepend">
                                                                    <button type="button"
                                                                        class="btn quantity-left-minus"
                                                                        data-type="minus" (click)="decrement()">
                                                                        <i class="ti-minus"></i>
                                                                    </button>
                                                                </span>
                                                                <input type="text" name="quantity"
                                                                    class="form-control input-number" [value]="counter"
                                                                    disabled />
                                                                <span class="input-group-prepend">
                                                                    <button type="button"
                                                                        class="btn quantity-right-plus" data-type="plus"
                                                                        (click)="increment(product)">
                                                                        <i class="ti-plus"></i>
                                                                    </button>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="mt-2 notify-me-button" *ngIf="product?.active == 2"
                                                [attr.disabeld]="true">
                                                <a class="btn btn-animation" (click)="getNoteDetails(2)">
                                                    <i class="fa fa-bell" aria-hidden="true"></i>
                                                    {{'Inventory is in progress' | translate}}
                                                </a>
                                            </div>

                                            <div class="add-to-cart-button d-flex flex-column align-items-stretch"
                                                *ngIf="product?.active != 2"
                                                [ngClass]="(product?.store_amounts == 0 && product?.price == 0) ||(product?.store_amounts == 0 && product?.price> 0) ? 'product-buttons-out-of-stock' : 'product-buttons'">

                                                <a *ngIf="(product?.store_amounts > 0 && product?.price > 0)"
                                                    href="javascript:void(0)" class="btn btn-animation"
                                                    [class.disabled]="counter > product?.stock"
                                                    (click)="addToCart(product)">
                                                    <i class="fa fa-shopping-cart me-1" aria-hidden="true"></i>
                                                    {{'add-to-cart' | translate}}
                                                </a>

                                                <a *ngIf="(product?.store_amounts == 0 && product?.price == 0) || (product?.store_amounts == 0 && product?.price > 0) "
                                                    href="javascript:void(0)" class="btn btn-animation"
                                                    [class.disabled]="counter > product?.stock">
                                                    {{'out of stock' | translate}}
                                                </a>

                                                <a *ngIf="(product?.store_amounts> 0 && product?.price == 0) "
                                                    (click)="getNoteDetails(1)" class="btn1 btn-animation"
                                                    [class.disabled]="counter > product?.stock">
                                                    {{'Soon' | translate}}
                                                </a>

                                                <div class="buy-now-button">
                                                    <a *ngIf="(product?.store_amounts !== 0 && product?.price > 0)"
                                                        href="javascript:void(0)" class="btn btn-solid"
                                                        [class.disabled]="counter> product?.stock"
                                                        (click)="buyNow(product)">
                                                        {{'buy now' | translate}}
                                                    </a>
                                                </div>

                                                <div class="notify-me-button"
                                                    *ngIf="(product?.store_amounts == 0 && product?.price == 0) ||(product?.store_amounts == 0 && product?.price> 0) ">
                                                    <a class="btn btn-animation"
                                                        (click)="openNotifyMeModal(notifyModal, product?.id)">
                                                        <i class="fa fa-bell" aria-hidden="true"></i>
                                                        {{'Notify me' | translate}}
                                                    </a>
                                                </div>
                                            </div>


                                            <div class="border-product">
                                                <div class="product-icon">
                                                    <form class="d-inline-block">
                                                        <button class="wishlist-btn" (click)="addToWishlist(product)">
                                                            <i class="fa fa-heart"
                                                                [ngClass]="isFavourit ? 'text-danger' : 'text-icon'"></i>
                                                        </button>
                                                        <span class="title-font font-weight-bold">{{'Add To WishList' |
                                                            translate}}</span>
                                                    </form>
                                                </div>
                                            </div>

                                        </div>

                                        <div class="add-to-cart-main"
                                            *ngIf="product?.api_id_to > 0 || product?.seller_name !=''">
                                            <div class="price-quantity-block">
                                                <div class="sell-item" *ngIf="product?.seller_name !=''">
                                                    <!-- <h3 class="price-detail seller-item-content d-inline-block seller-title-style"
                                                        [class.seller-title-no-style]="product?.api_id_to <= 0">
                                                        <img width="35" height="35"
                                                            style="border: 2px solid #DDD; border-radius: 50%;"
                                                            [src]="Common._ImageUrl +'front_images/'+settings['front_fav_icon']"
                                                            alt="" srcset="">
                                                        {{'seller' | translate}}
                                                        <span
                                                            class="company company-name">{{product?.seller_name}}</span>
                                                    </h3> -->
                                                </div>
                                                <div class="product-price" *ngIf="product?.api_id_to > 0">
                                                    <h3 class="price-detail d-inline-block company-title-style"
                                                        *ngIf="(product?.price > 0 && product?.store_amounts > 0)">
                                                        <img width="35" height="35"
                                                            style="border: 2px solid #DDD; border-radius: 50%;"
                                                            [src]="importApiImage + product?.details_store_external?.image"
                                                            alt="" srcset="">
                                                        <svg class="w-6 h-6 text-gray-800 dark:text-white"
                                                            aria-hidden="true"
                                                            *ngIf="product?.details_store_external?.image == ''"
                                                            xmlns="http://www.w3.org/2000/svg" fill="none"
                                                            viewBox="0 0 20 18">
                                                            <path stroke="currentColor" stroke-linecap="round"
                                                                stroke-linejoin="round" stroke-width="2"
                                                                d="M10 12.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Z" />
                                                            <path stroke="currentColor" stroke-linecap="round"
                                                                stroke-linejoin="round" stroke-width="2"
                                                                d="M17 3h-2l-.447-.894A2 2 0 0 0 12.764 1H7.236a2 2 0 0 0-1.789 1.106L5 3H3a2 2 0 0 0-2 2v11a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V5a2 2 0 0 0-2-2Z" />
                                                        </svg>
                                                        {{ product?.details_store_external?.name }}
                                                    </h3>
                                                </div>

                                                <div class="product-quantity"
                                                    *ngIf="((product?.store_amounts !== 0 && product?.price !== 0) || (product?.store_amounts !== 0 && product?.price!> 0) )&& product?.api_id_to > 0 ">
                                                    <h5 class="text-danger" style="font-size: 10px;
                                                    font-weight: bold;
                                                    margin-right: 10px;
                                                    width: fit-content;">
                                                        {{ ("This product ships from " | translate ) +
                                                        product?.details_store_external?.name}} </h5>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="add-to-cart-shipping ">
                                            <div class="single-block">
                                                <div class="image-side">
                                                    <img src="assets/images/product/delivery1.png">
                                                </div>
                                                <div class="feature-title-side">
                                                    <span>
                                                        {{'home delivery' | translate}}
                                                    </span>

                                                </div>
                                            </div>
                                            <div class="single-block">
                                                <div class="image-side">
                                                    <img src="assets/images/product/pod.png">
                                                </div>
                                                <div class="feature-title-side">
                                                    <span>
                                                        {{'pod' | translate}}
                                                    </span>

                                                </div>
                                            </div>
                                            <div class="single-block">
                                                <div class="image-side">
                                                    <img src="assets/images/product/support.png">
                                                </div>
                                                <div class="feature-title-side">
                                                    <span>
                                                        {{'technical support on orders' | translate}}
                                                    </span>

                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </div>

                        </div>

                        <div class="mobile-product-info-layout">
                            <div>
                                <div class="list-style">
                                    <ul>

                                        <li *ngIf="(product?.store_amounts> 0 && product?.price > 0 )">
                                            <span class="info-key-title">
                                                {{'product Status' | translate}} :
                                            </span>
                                            <span class="text-success">
                                                {{'available' | translate}}
                                            </span>

                                        </li>

                                        <li
                                            *ngIf="(product?.store_amounts == 0 && product?.price == 0) ||(product?.store_amounts == 0 && product?.price > 0) ">
                                            <span class="info-key-title">
                                                {{'product Status' | translate}} :
                                            </span>
                                            <span class="text-danger">
                                                {{'out of stock' | translate}}
                                            </span>
                                        </li>

                                        <li *ngIf="(product?.store_amounts> 0 && product?.price == 0) ">
                                            <span class="info-key-title">
                                                {{'product Status' | translate}} :
                                            </span>
                                            <span class="text-warning">
                                                {{'Soon' | translate}}
                                            </span>
                                        </li>

                                        <li *ngIf="product?.product_code">
                                            <span class="info-key-title">
                                                {{'product code' | translate}} :
                                            </span>
                                            <span style="color: #222222;">
                                                {{product?.product_code}}
                                            </span>
                                        </li>

                                        <li>
                                            <span class="info-key-title">
                                                {{'barcode' | translate}} :
                                            </span>
                                            {{product?.product_barcode}}
                                        </li>
                                        <li *ngIf="product?.trademark_title">
                                            <span class="info-key-title">
                                                {{'brand' | translate}} :
                                            </span>
                                            <a style="text-decoration: underline;"
                                                [routerLink]="['/shop/trademarkes/'  , product?.trademark_id , product?.trademark_title?.split(' ').join('-')]">
                                                <span style="color: #222222;">
                                                    {{product?.trademark_title}}
                                                </span>
                                            </a>
                                        </li>
                                        <li class="align-items-end">
                                            <del *ngIf="product?.price > 0 && (product?.last_price > 0)" class="pt-1 "
                                                [class]="Common.isRtl ? 'ml-3' : 'mr-3'">
                                                {{ product?.last_price | number:'1.3-3':'1.3-3' }} {{currency?.currency}}
                                            </del>
                                            <span class="price"
                                                *ngIf="product?.price > 0 && product?.store_amounts != 0">
                                                {{product?.price | number:'1.3-3'}} {{productService?.Currency.name}}
                                            </span>

                                        </li>

                                    </ul>
                                </div>
                            </div>
                            <div *ngIf="optionsData.length>1">
                                <hr>
                                <span class="mb-3 product-title added-title">
                                    {{'Choose an option'|translate}}
                                </span>
                                <div class="flex-wrap gap-3 d-flex">
                                    <div (click)="chooseOptions(i,item)" class="option-choose-data"
                                        [class.active]="i==optionChekced"
                                        *ngFor="let item of optionsData let first=first let i =index">
                                        <span>{{item.name}}</span>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <hr *ngIf="productFormShapesDetails.length > 0">
                                <span class="mb-2 product-title added-title" *ngIf="productFormShapesDetails.length >0">
                                    {{"Specifications" | translate}}:
                                </span>
                                <div class="list-style">
                                    <ul class="d-flex flex-column align-items-start ">
                                        <li *ngFor="let form of productFormShapesDetails; let index=index; let last=last"
                                            class="flex-nowrap d-flex " [class.p-0]="last">
                                            <i class="fa fa-solid fa-circle"></i>
                                            <span class="info-key-title" style="white-space: nowrap;">
                                                {{form.form_name | translate}}
                                                <i class="fa fa-exclamation-circle pointer-event "
                                                    *ngIf="!form.form_shape_id" (click)="getNoteDetails(3)"></i>
                                                :&nbsp;
                                            </span>
                                            <span style="color: #222222;"
                                                *ngIf="form.type != 'm_select' && form.type != 'text' &&form.type">
                                                <a style="text-decoration: underline;"
                                                    [routerLink]="['/pages/formshapes/' + form.form_value]">{{form.form_value}}</a>

                                            </span>
                                            <span style="color: #222222;" *ngIf="form.type == 'text'">
                                                <a>{{form.form_value}}</a>
                                            </span>
                                            <span style="color: #222222;" *ngIf="!form.type">
                                                <a>{{form.form_value}}</a>
                                            </span>
                                            <div class="flex-wrap d-flex align-items-start">
                                                <ng-container *ngIf="form.type == 'm_select'">
                                                    <span style="color: #222222; white-space:nowrap"
                                                        *ngFor="let select of multi_select[index]; let s=index; last as isLast">
                                                        <a style="text-decoration: underline;"
                                                            [routerLink]="['/pages/formshapes/' + select]">{{select}}
                                                            <span class="mx-2 d-inline-block" *ngIf="!isLast"> -
                                                            </span>
                                                        </a>

                                                    </span>
                                                </ng-container>
                                            </div>


                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="container-mobile">
                            <hr *ngIf="ProductQuantity >= 1">
                            <span class="mb-3 product-title added-title" *ngIf="ProductQuantity >= 1">
                                {{'Enjoy the quantitative discount'|translate}}
                            </span>
                            <ul class="row" *ngIf="ProductQuantity >= 1">
                                <li class="col-md-10" *ngFor="let disc of quantity_discount let d=index">
                                    <ng-container
                                        *ngIf="disc.quantity <= this.product?.stores[0]['store_amounts_product'] && +(disc.quantity) > 1">
                                        <div class="mb-2 cursor-pointer d-flex justify-content-between align-items-center discount-item "
                                            (click)="addProductDiscountToCart(disc)">

                                            <div class="d-flex align-items-center">
                                                <img src="assets/images/hot-deal-ezgif.com-resize.webp" width="30"
                                                    alt="">
                                                <p class="px-2 m-0 text-black text-size p-line related-text"
                                                    *ngIf="disc.purchase_text !='' && disc.purchase_text!=null">
                                                    {{disc.purchase_text}}</p>

                                                <p class="px-2 m-0 text-black text-size p-line related-text"
                                                    *ngIf="disc.purchase_text =='' || disc.purchase_text==null">
                                                    {{'buy'|translate}}
                                                    {{disc.quantity}} {{'price'|translate}}
                                                    {{(disc.quantity*disc.unit_price )| number:'1.3-3'}}
                                                </p>

                                            </div>
                                            <div class="d-flex align-items-center">
                                                <p class="px-2 m-0 text-black text-size related-text">
                                                    {{disc.unit_price | number:'1.3-3'}} {{
                                                    currency?.currency }}</p>
                                                <p style="text-decoration: line-through;"
                                                    class="m-0 text-black text-muted text-size related-text"
                                                    *ngIf="unitPricefor1quantity">
                                                    {{unitPricefor1quantity | number:'1.3-3'
                                                    }} {{ currency?.currency }}</p>
                                            </div>
                                        </div>
                                    </ng-container>

                                </li>

                            </ul>
                            <hr *ngIf="related_product?.length > 1">
                            <span class="mb-3 product-title added-title" *ngIf="related_product?.length > 1">
                                {{'Other options are available'|translate}}
                            </span>

                            <ng-container *ngIf="related_product?.length > 1">
                                <div class="options-container">
                                    <div class="text-center options-item" [class.active]="index == 0"
                                        [class.cursor-pointer]="index != 0"
                                        *ngFor="let prod of related_product; let index=index"
                                        [routerLink]="index != 0 ? ['/shop/product/', prod.id, prod.meta_url ? prod.meta_url?.split(' ').join('-').replace('+', '') : removeBrackets(prod.product_name)?.split(' ').join('-').replace('+', '')] : null">

                                        <p class="text-center text-black option_title text-size">
                                            {{prod.related_details}}</p>
                                        <div
                                            *ngIf="(prod.store_amounts > 0 && prod.price > 0 && prod?.stores?.length == 1)">
                                            <span class="mr-2 text-black text-size" [class.text-red]="prod.last_price"
                                                [class.text-black]=" !prod.last_price">{{prod.price}}
                                                {{productService?.Currency.name}}</span>
                                            <span class="mr-2 text-black text-muted text-size"
                                                style="text-decoration: line-through;"
                                                *ngIf="prod.last_price">{{prod.last_price}}
                                                {{productService?.Currency.name}}</span>
                                        </div>
                                        <span class="text-stock text-size"
                                            *ngIf="(prod.store_amounts == 0 && prod.price == 0) || (prod.store_amounts == 0 && prod.price > 0)">
                                            {{'Out of stock' | translate}}
                                        </span>
                                        <span class="text-soon text-size"
                                            *ngIf="(prod.store_amounts > 0 && prod.price == 0)">
                                            {{'Soon' | translate}}
                                        </span>
                                    </div>
                                </div>
                            </ng-container>

                            <hr *ngIf="similar_product?.length >1">

                            <div class=" similar-container container-fit position-relative"
                                *ngIf="similar_product?.length > 1 &&similar_product?.length <3">
                                <h2 class="product-title" [style.color]="calculateTextColor()">
                                    {{'Products that are frequently purchased together'|translate}}</h2>
                                <div class="similar-product d-flex">
                                    <ng-container *ngIf="similar_product?.length < 3">
                                        <div class="d-flex align-items-center related-products-slide1"
                                            *ngFor="let sProduct of similar_product; let s = index; last as isLast">
                                            <div class="product-box">
                                                <div class="product-box-inner">
                                                    <div class="img-wrapper position-relative">
                                                        <input type="checkbox" class="form-check product-check"
                                                            [checked]="sProduct.checked"
                                                            (change)="toggleProductChecked(s)">
                                                        <div class="text-center" [class.cursor-pointer]="s != 0"
                                                            [routerLink]="s != 0 ?  ['/shop/product/', sProduct.id , sProduct.meta_url ? sProduct.meta_url?.split(' ').join('-').replace('+' , '') : removeBrackets(sProduct.product_name)?.split(' ').join('-').replace('+' , '') ] :null">
                                                            <div class="front">
                                                                <a>
                                                                    <div class="text-center image-frame">
                                                                        <img [src]="(sProduct.images.length > 0 ? urlimage + sProduct.images[0].original_name : 'assets/images/product/placeholder.jpg')"
                                                                            [lazyLoad]="urlimage + sProduct.original_name"
                                                                            class="img-fluid lazy-loading"
                                                                            (error)="sProduct.images.length = 0; $event.target.src = 'assets/images/product/placeholder.jpg';"
                                                                            alt="Product Image" />
                                                                    </div>
                                                                </a>
                                                            </div>
                                                            <h3 class="text-center text-black text-size">
                                                                {{
                                                                sProduct.product_name
                                                                }}</h3>
                                                            <p class="text-center text-black product-price text-size">
                                                                {{
                                                                sProduct.price | number:'1.3-3' }} {{
                                                                productService?.Currency.name }}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <p class="plus" [ngStyle]="isLast?{'opacity':'0'}:{'opacity':'1'}">+</p>

                                        </div>
                                    </ng-container>
                                </div>
                                <button type="button" class="btn w-100" [disabled]="selectedProductsCount === 0"
                                    (click)="!isloading && addSimilarToCart()">
                                    <h5 class="flex-wrap cursor-pointer d-flex justify-content-between product-title">
                                        <span class="product_title">
                                            {{'addproduct'|translate}} {{selectedProductsCount +"
                                            "}}{{'to cart'|translate}} </span>
                                        <span class="product_title">|</span>
                                        <div>
                                            <span class="product_title">{{ totalPrice }} {{
                                                productService?.Currency.name }}</span>
                                            <span *ngIf="isloading" class="cutom-loader"></span>
                                        </div>
                                    </h5>
                                </button>
                            </div>
                            <div class="similar-container position-relative" *ngIf="similar_product?.length>=3">
                                <h2 class="product-title" [style.color]="calculateTextColor()">
                                    {{'Products that are frequently purchased together'|translate}}
                                </h2>
                                <div class="similar-product">

                                    <swiper [config]="similarProductsSliderConfig">
                                        <ng-template class="d-flex align-items-center related-products-slide1"
                                            *ngFor="let sProduct of similar_product; let s = index; last as isLast"
                                            swiperSlide>
                                            <div class="product-box">
                                                <div class="product-box-inner">
                                                    <div class="img-wrapper position-relative">
                                                        <input type="checkbox" class="form-check product-check"
                                                            [checked]="sProduct.checked"
                                                            (change)="toggleProductChecked(s)">
                                                        <div class="text-center" [class.cursor-pointer]="s != 0"
                                                            [routerLink]="s != 0 ?  ['/shop/product/', sProduct.id , sProduct.meta_url ? sProduct.meta_url?.split(' ').join('-').replace('+' , '') : removeBrackets(sProduct.product_name)?.split(' ').join('-').replace('+' , '') ] :null">
                                                            <div class="front">
                                                                <a>
                                                                    <div class="text-center image-frame">
                                                                        <img [src]="(sProduct.images.length > 0 ? urlimage + sProduct.images[0].original_name : 'assets/images/product/placeholder.jpg')"
                                                                            [lazyLoad]="urlimage + sProduct.original_name"
                                                                            class="img-fluid lazy-loading"
                                                                            (error)="sProduct.images.length = 0; $event.target.src = 'assets/images/product/placeholder.jpg';"
                                                                            alt="Product Image" />
                                                                    </div>
                                                                </a>
                                                            </div>
                                                            <h3 class="text-center text-black text-size">
                                                                {{
                                                                sProduct.product_name
                                                                }}</h3>
                                                            <p class="text-center text-black product-price text-size">
                                                                {{
                                                                sProduct.price | number:'1.3-3' }} {{
                                                                productService?.Currency.name }}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <p class="plus" [ngStyle]="isLast?{'opacity':'0'}:{'opacity':'1'}">+</p>

                                        </ng-template>
                                    </swiper>
                                    <button type="button" class="btn w-100" [disabled]="selectedProductsCount === 0"
                                        (click)="!isloading && addSimilarToCart()">
                                        <h5
                                            class="flex-wrap cursor-pointer d-flex justify-content-between product-title">
                                            <span class="product_title">
                                                {{'addproduct'|translate}} {{selectedProductsCount +" "}}{{'to cart'|translate}} </span>
                                            <span class="product_title">|</span>
                                            <div>
                                                <span class="product_title">{{ totalPrice }} {{
                                                    productService?.Currency.name }}</span>
                                                <span *ngIf="isloading" class="cutom-loader"></span>
                                            </div>
                                        </h5>
                                    </button>
                                </div>
                            </div>
                            <hr>
                            <div class="add-to-cart-main" *ngIf="product?.api_id_to > 0 || product?.seller_name !=''">
                                <div class="price-quantity-block product-price-mobile">
                                    <div class="sell-item text-start" *ngIf="product?.seller_name !=''">
                                        <!-- <h3 class="price-detail seller-item-content d-inline-block seller-title-style"
                                            [class.seller-title-no-style]="product?.api_id_to <= 0">
                                            <img width="35" height="35"
                                                style="border: 2px solid #DDD; border-radius: 50%;"
                                                [src]="Common._ImageUrl +'front_images/'+settings['front_fav_icon']"
                                                alt="" srcset="">
                                            {{'seller' | translate}}
                                            <span class="company company-name">{{product?.seller_name}}</span>
                                        </h3> -->
                                    </div>
                                    <div class="product-price text-start" *ngIf="product?.api_id_to > 0">
                                        <h3 class="text-black price-detail d-inline-block company-title-style"
                                            *ngIf="(product?.price > 0 && product?.store_amounts > 0)">
                                            <img width="35" height="35"
                                                style="border: 2px solid #DDD; border-radius: 50%;"
                                                [src]="importApiImage + product?.details_store_external?.image" alt=""
                                                srcset="">
                                            <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true"
                                                *ngIf="product?.details_store_external?.image == ''"
                                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 18">
                                                <path stroke="currentColor" stroke-linecap="round"
                                                    stroke-linejoin="round" stroke-width="2"
                                                    d="M10 12.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Z" />
                                                <path stroke="currentColor" stroke-linecap="round"
                                                    stroke-linejoin="round" stroke-width="2"
                                                    d="M17 3h-2l-.447-.894A2 2 0 0 0 12.764 1H7.236a2 2 0 0 0-1.789 1.106L5 3H3a2 2 0 0 0-2 2v11a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V5a2 2 0 0 0-2-2Z" />
                                            </svg>
                                            {{ product?.details_store_external?.name }}
                                        </h3>
                                    </div>

                                    <div class="product-quantity"
                                        *ngIf="((product?.store_amounts !== 0 && product?.price !== 0) || (product?.store_amounts !== 0 && product?.price!> 0) )&& product?.api_id_to > 0 ">
                                        <h5 class="text-danger" style="font-size: 10px;
                                                                                font-weight: bold;
                                                                                margin-right: 10px;
                                                                                width: fit-content;">
                                            {{ ("This product ships from " | translate ) +
                                            product?.details_store_external?.name}} </h5>
                                    </div>
                                </div>
                                <hr>
                            </div>
                        </div>

                        <div class="tabs-layout-descriptions">
                            <div class="tabs-block">
                                <div class="tab-single"
                                    *ngIf="settings?.full_description_web == '1' && product?.description"
                                    [ngClass]="tabId == 'general' ? 'tab-single-active' : ''"
                                    (click)="tabChange('general')">
                                    <h2 class="">
                                        {{"General-Description" | translate}}
                                    </h2>

                                </div>
                                <div class="tab-single" *ngIf="productFormShapesDetails.length > 0"
                                    [ngClass]="tabId == 'description' ? 'tab-single-active' : ''"
                                    (click)="tabChange('description')">
                                    <h2 class="">
                                        {{"Specifications" | translate}}
                                    </h2>
                                </div>
                                <div class="tab-single"
                                    *ngIf="settings?.promotional_description_web == '1' && product['promotional_description']"
                                    [ngClass]="tabId == 'promotional' ? 'tab-single-active' : ''"
                                    (click)="tabChange('promotional')">
                                    <h2 class="">
                                        {{"promotional description" | translate}}
                                    </h2>
                                </div>
                            </div>

                            <div class="tab-general-content" *ngIf="tabId === 'general' && settings?.full_description_web == '1' &&
                                product?.description" [style.text-align]="Common.isRtl ? 'right' : 'left'">

                                <div class=" productDescription" *ngIf="product?.description"
                                    [innerHTML]="product?.description | sanitizeHtml: 'html'">
                                </div>

                                <!-- <div class="label-section">
                                            <span class="badge badge-grey-color" *ngIf="(product?.store_amounts> 0 &&
                                            product?.price > 0 )">In Stock</span>
                                            <span class="badge badge-grey-color" *ngIf="!(product?.store_amounts> 0 &&
                                            product?.price > 0 )">Out of
                                                Stock</span>
                                        </div> -->
                            </div>


                            <div class="tab-description-content"
                                *ngIf="tabId === 'description' && productFormShapesDetails.length != 0">

                                <div class="no-content" *ngIf="productFormShapesDetails.length == 0">
                                    <img src="assets/images/empty-search1.png" class="img-fluid">
                                    <p>
                                        {{"No-Specifications" | translate}}
                                    </p>
                                </div>

                                <div class="description-content-block">
                                    <div *ngFor="let form of productFormShapesDetails; let index=index;"
                                        class="discription-content-single"
                                        [ngClass]="{'red-background-single': 0 === index % 2}">
                                        <div class="discription-title" style="font-weight: bold">
                                            <span class="">
                                                {{form.form_name | translate}}
                                                <i class="fa fa-exclamation-circle pointer-event "
                                                    *ngIf="!form.form_shape_id" (click)="getNoteDetails(3)"></i>
                                            </span>
                                        </div>
                                        <div class="discription-title"
                                            *ngIf="form.type != 'm_select' && form.type != 'text' &&form.type">
                                            <span class="">
                                                <a class="form-shape"
                                                    [routerLink]="['/pages/formshapes/' + form.form_value]">{{form.form_value}}</a>
                                            </span>
                                        </div>
                                        <div class="discription-title" *ngIf="form.type == 'text'">
                                            <span class="">
                                                <a>{{form.form_value}}</a>
                                            </span>
                                        </div>
                                        <div class="discription-title" *ngIf="!form.type">
                                            <span class="">
                                                <a>{{form.form_value}}</a>
                                            </span>
                                        </div>
                                        <div class="flex-row flex-wrap discription-title"
                                            *ngIf="form.type == 'm_select'">
                                            <ng-container
                                                *ngFor="let select of multi_select[index]; let s=index; last as isLast">
                                                <a class="form-shape"
                                                    [routerLink]="['/pages/formshapes/' + select]">{{select}}</a>
                                                <span class="mx-2 d-inline-block" *ngIf="!isLast"> - </span>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>

                            </div>


                            <div class="tab-general-content" *ngIf="tabId === 'promotional' && settings?.promotional_description_web == '1' &&
                                product['promotional_description']"
                                [style.text-align]="Common.isRtl ? 'right' : 'left'">

                                <div class="" *ngIf="product['promotional_description']"
                                    [innerHTML]="product['promotional_description'] | sanitizeHtml: 'html'">
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


        </div>
    </div>
    <section class="section-b-space related-products">
        <div class="container">
            <div class="row">
                <div class="col-12 product-related">
                    <h2 class="font-title font-weight-bold">{{'RELATED PRODUCTS' | translate}}</h2>
                </div>
            </div>

            <div class="row search-product">
                <div class="col">
                    <!-- <owl-carousel-o class="product-m no-arrow" [options]="ProductSliderConfig">
                        <ng-container *ngFor="let product of relatedproducts | slice:0:4">
                            <ng-template carouselSlide>
                                <app-product-box-one [product]="product" [currency]="productService?.Currency"
                                    [thumbnail]="false" [cartModal]="true">
                                </app-product-box-one>
                            </ng-template>
                        </ng-container>
                    </owl-carousel-o> -->

                    <app-related-products-slider [productsList]="relatedproducts | slice:0:9">
                    </app-related-products-slider>


                    <!-- <swiper [config]="relatedProductsSliderConfig">
                        <ng-template class="related-products-slide" 
                            *ngFor="let product of relatedproducts | slice:0:4" swiperSlide>
                            <app-product-box-one [product]="product" [currency]="productService?.Currency"
                                [thumbnail]="false" [cartModal]="true">
                            </app-product-box-one>
                        </ng-template>
                    </swiper> -->
                </div>
            </div>


        </div>
    </section>
</section>
<app-size-modal #sizeChart [product]="product"></app-size-modal>
<!-- Section ends -->

<!-- FLOATING ADD TO CART BOTTOM BLOCK MOBILE SCREEN -->
<div class="add-to-cart-mobile-block">
    <div class="qty-box">
        <div class="input-group">
            <span class="input-group-prepend">
                <button type="button" class="btn quantity-left-minus" data-type="minus" (click)="decrement()">
                    <i class="ti-minus"></i>
                </button>
            </span>
            <input type="text" name="quantity" class="form-control input-number" [value]="counter" disabled />
            <span class="input-group-prepend">
                <button type="button" class="btn quantity-right-plus" data-type="plus" (click)="increment(product)">
                    <i class="ti-plus"></i>
                </button>
            </span>
        </div>
    </div>

    <button class="add-to-cart-button" *ngIf="product?.active != 2"
        [ngClass]="(product?.store_amounts == 0 && product?.price == 0) ||(product?.store_amounts == 0 && product?.price> 0) ? 'product-buttons-out-of-stock' : 'product-buttons'">
        <a href="javascript:void(0)" *ngIf="(product?.store_amounts > 0 && product?.price > 0 )"
            class="btn btn-animation" [class.disabled]="counter > product?.stock" (click)="addToCart(product)">
            <!-- <i class="fa fa-shopping-cart me-1"
                aria-hidden="true"></i> -->
            {{'add-to-cart' | translate}}
        </a>
        <a *ngIf="(product?.store_amounts == 0 && product?.price == 0) || (product?.store_amounts == 0 && product?.price > 0) "
            href="javascript:void(0)" class="btn btn-animation" [class.disabled]="counter > product?.stock">
            {{'out of stock' | translate}}
        </a>
        <a *ngIf="(product?.store_amounts> 0 && product?.price == 0) " href="javascript:void(0)"
            class="btn1 btn-animation" [class.disabled]="counter > product?.stock">
            {{'Soon' | translate}}
        </a>
    </button>

    <button class="notify-me-button border-test" (click)="openNotifyMeModal(notifyModal, product?.id)"
        *ngIf="(product?.store_amounts == 0 && product?.price == 0) ||(product?.store_amounts == 0 && product?.price> 0) && product?.active != 2 ">
        <!-- <i class="fa fa-bell" aria-hidden="true"></i> -->
        {{'Notify me' | translate}}
    </button>

    <button class="buy-now-button" *ngIf="(product?.store_amounts !== 0 && product?.price > 0) && product?.active != 2">
        <a href="javascript:void(0)" class="btn btn-solid" [class.disabled]="counter> product?.stock"
            (click)="buyNow(product)">
            {{'buy now' | translate}}
        </a>
    </button>
    <button class="notify-me-button Inventory-progress-button" *ngIf="product?.active == 2" [attr.disabeld]="true">
        <a class="btn btn-animation" (click)="getNoteDetails(2)">
            <i class="fa fa-bell" aria-hidden="true"></i>
            {{'Inventory is in progress' | translate}}
        </a>
    </button>

</div>

<div *ngIf="schema['name']">
    <ngx-json-ld [json]="schema"></ngx-json-ld>
</div>

<ng-template #notifyModal let-modal>
    <app-notify-me-modal [productIdToListenToAvailability]="product?.id"></app-notify-me-modal>
</ng-template>

<!-- Notes Prodcut Status -->
<ng-template class="notesSheet" let-bottomSheetRef="bottomSheetRef" #templateBottomSheet>

    <!-- <div class="top-0 left-0 layer-box w-100 h-100 bg-danger" style="position: fixed  ;z-index: 99999 ; bottom: 100%;"></div> -->
    <div class="d-flex justify-content-between align-items-center note_title"
        style="border-bottom: 1px solid #EEE; background: #fff; margin-inline: 10px;">
        <div id="note_title" style="font-size: 20px;" *ngIf="note_id === 1">قريبا</div>
        <div id="note_title" style="font-size: 20px;" *ngIf="note_id === 2">ملاحظة جاري الجرد</div>
        <div id="note_title" style="font-size: 20px;" *ngIf="note_id === 3">ملاحظة تاريخ الصلاحية</div>

        <button (click)="closeTemplateSheetMenu()" class="bg-transparent border-0 text-dark ">
            <mat-icon>close</mat-icon>
        </button>
    </div>


    <div id="note_description" class="p-2 text-center">

    </div>
    <!-- Loader -->
    <div class="pt-1 text-center loader" *ngIf="noteLoader">
        <img src="assets/images/loader.gif">
    </div>

</ng-template>

<section #login class="full-sidebar" *ngIf="loginclicked">
    <div #loginFast class="shadow login-sidebar">
        <i class="ti-close login-icon" (click)="close()"></i>
        <app-fast-login (step5Reached)="onStep5Reached()"
            (stepSuccessReached)="onstepSuccessReached()"></app-fast-login>
    </div>
</section>