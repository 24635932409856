<div class="single-item-block">

    <div class="d-flex">

        <a class="img-block" [routerLink]="['/shop/product/', product.id]">
            <img [src]="imageUrl + product?.image" [alt]="product.name">
        </a>

        <div class="info-block">
            <a class="title" [routerLink]="['/shop/product/', product.id]">
                {{product.name}}
            </a>
            <div class="price-block" *ngIf="product.price > 0 && product.store_amounts > 0">
                <span class="price">
                    {{ (product.price | discount:product) * productService?.Currency.price }}
                    {{productService?.Currency.name}}
                </span>
                <del *ngIf="product?.price > 0 && (product?.last_price > 0)" class="old-price">{{ product?.last_price }}
                    {{productService?.Currency.name}}</del>
            </div>

            <span class="soon" *ngIf="(product.store_amounts> 0 && product.price == 0)">
                {{'Soon' | translate}}
            </span>
            <span class="out-of-stock"
                *ngIf="(product.store_amounts == 0 && product.price == 0) ||(product.store_amounts == 0 && product.price> 0)">
                {{'out of stock' | translate}}
            </span>
        </div>

    </div>

    <div class="options-block" (click)="removeItem()">
        <div class="delete">
            <i class="fa fa-trash" aria-hidden="true" title="{{'Delete' | translate}}"></i>
        </div>

        <div class="add-to-cart" (click)="addToCart()" *ngIf="product.price > 0 && product.store_amounts > 0">
            <i class="fa fa-cart-plus" aria-hidden="true" title="{{'add-to-cart' | translate}}"></i>

        </div>


    </div>

</div>